import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import FileManagerContext from "../../../context/FileManagerContext";
import { Directory as DirectoryType } from "../../../types/Directory";
import { Item } from "../../../pages/FileManager/FileManager";
import "./Directory.scss";
import {
  useCreateDirectoryMutation,
  useDeleteDirectoryMutation,
  useUpdateDirectoryMutation,
} from "../../../services/FileManagerService";
import { dir } from "console";
import { useByDomainQuery } from "../../../services/WebsiteService";

export interface DirectoryProps {
  directory?: DirectoryType;
  editmode?: boolean;
  onSubmit?: any;
}

const Directory = (props: DirectoryProps) => {
  const {
    selected,
    setSelected,
    multiple,
    onChangeDirectory,
    directory: parentDirectory,
  } = useContext(FileManagerContext);
  const [editMode, setEditMode] = useState<boolean>(props.editmode ?? false);
  const { data: websiteResponse } = useByDomainQuery(
    document.location.hostname
  );
  const website = websiteResponse?.data;

  const [updateDirectory] = useUpdateDirectoryMutation();
  const [createDirectory] = useCreateDirectoryMutation();
  const [deleteDirectory] = useDeleteDirectoryMutation();

  const isSelected = selected.find(
    (s) => s.id === props.directory?.id && s.type === "directory"
  );

  const handleOnClick = (e: any) => {
    if (props.directory) {
      const withCtrl = e.metaKey;
      e.preventDefault();
      e.stopPropagation();
      let newSelected: Item[] = [];

      if (e.detail == 1) {
        if (withCtrl) {
          newSelected = multiple
            ? selected.filter(
                (s) =>
                  s.id !== props.directory?.id &&
                  s.type === props.directory?.type
              )
            : [];

          if (!isSelected) {
            newSelected.push(props.directory);
          }
        } else {
          newSelected.push(props.directory);
          if (isSelected) {
            setEditMode(true);
          }
        }

        setSelected(newSelected);
      }
    }
  };

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      setEditMode(false);
      setSelected([]);
      if (props.directory) {
        updateDirectory({
          ...props.directory,
          name: e.target.value,
        });
      } else {
        if (website && parentDirectory) {
          createDirectory({
            websiteId: website.id,
            directory: {
              name: e.target.value,
              parent_id: parentDirectory.id,
              type: "directory",
              directories: [],
              files: [],
            },
          });
        }
      }
      if (props.onSubmit) {
        props.onSubmit({ ...props.directory, name: e.target.value });
      }
    }
  };

  const handleOnDelete = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    Swal.fire({
      icon: "question",
      title: "Czy jesteś pewien?",
      showCancelButton: true,
      cancelButtonText: "Nie",
      confirmButtonText: "Tak",
    }).then((result) => {
      if (result.isConfirmed) {
        if (props.directory) {
          deleteDirectory(props.directory);
        }
      }
    });
  };

  const cancelEditMode = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setEditMode(false);
  };
  const handleOnDoubleClick = (e: any) => {
    setSelected([]);
    e.preventDefault();
    e.stopPropagation();
    onChangeDirectory(props.directory?.id);
  };

  return (
    <>
      <div
        className={"filemanager-item " + (isSelected ? "selected" : "")}
        onClick={handleOnClick}
        onDoubleClick={handleOnDoubleClick}
      >
        <button className="filemanager-remove" onClick={handleOnDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
        <div className="filemanager-icon directory" />
        {editMode ? (
          <>
            <div className="overlay" onClick={cancelEditMode}></div>

            <input
              type="text"
              autoFocus
              onKeyDown={handleOnKeyDown}
              defaultValue={props.directory?.name}
              className="filemanager-label filemanager-label-input"
            />
          </>
        ) : (
          <div className="filemanager-label">{props.directory?.name}</div>
        )}
      </div>
    </>
  );
};

export default Directory;
