import { ListParams } from "../types/Api/ListParams";
import { SingleItemResponse, ListResponse } from "../types/Api/Response";
import { OnlyId } from "../types/OnlyId";
import { TaxonomyType } from "../types/TaxonomyType";

import Api from "./Api";

export const TaxonomyTypeService = Api.injectEndpoints({
  endpoints: (build) => ({
    getTaxonomyType: build.query<SingleItemResponse<TaxonomyType>, number>({
      query: (id) => `/private/taxonomy-types/${id}`,
    }),

    createTaxonomyType: build.mutation<
      SingleItemResponse<TaxonomyType>,
      { websiteId: number; taxonomyType: TaxonomyType }
    >({
      query: ({ websiteId, taxonomyType }) => ({
        url: `/private/websites/${websiteId}/taxonomy-types`,
        body: taxonomyType,
        method: "POST",
      }),
    }),
    taxonomyTypeList: build.query<
      ListResponse<TaxonomyType>,
      { websiteId?: number; params?: ListParams }
    >({
      query: ({ websiteId, params }) => ({
        url: `/private/websites/${websiteId}/taxonomy-types`,
        params: params,
      }),
    }),

    publicTaxonomies: build.query<
      ListResponse<TaxonomyType>,
      { taxonomyId: number }
    >({
      query: ({ taxonomyId }) =>
        `/public/taxonomy-types/${taxonomyId}/taxonomies`,
    }),

    updateTaxonomyType: build.mutation<
      SingleItemResponse<TaxonomyType>,
      TaxonomyType
    >({
      query: (taxonomyType) => ({
        url: `/private/taxonomy-types/${taxonomyType.id}`,
        body: taxonomyType,
        method: "PATCH",
      }),
    }),
    removeTaxonomyType: build.mutation<
      SingleItemResponse<TaxonomyType>,
      TaxonomyType | OnlyId
    >({
      query: ({ id }) => ({
        url: `/private/taxonomy-types/${id}`,

        method: "DELETE",
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreateTaxonomyTypeMutation,
  useGetTaxonomyTypeQuery,
  useLazyGetTaxonomyTypeQuery,
  useLazyPublicTaxonomiesQuery,
  useLazyTaxonomyTypeListQuery,
  usePrefetch,
  usePublicTaxonomiesQuery,
  useRemoveTaxonomyTypeMutation,
  useUpdateTaxonomyTypeMutation,
  useTaxonomyTypeListQuery,
} = TaxonomyTypeService;
