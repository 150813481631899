import { createContext } from "react";
import { Content } from "../types/Content";
import { ContentType } from "../types/ContentType";
import { Taxonomy } from "../types/Taxonomy";
import { TaxonomyType } from "../types/TaxonomyType";

interface LayoutContextType {
  data?: Content | ContentType | TaxonomyType | Taxonomy;
  datatype?:
    | "App\\Models\\Content"
    | "App\\Models\\ContentType"
    | "App\\Models\\TaxonomyType"
    | "App\\Models\\Taxonomy";
}
const LayoutContext = createContext<LayoutContextType>({});

export default LayoutContext;
