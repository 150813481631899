import {
  faEdit,
  faTrash,
  faUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Toolbar.scss";

const Toolbar = (props: any) => {
  const { editmode } = props;

  const handleRemove = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    props.delete(props);
  };
  return editmode === "true" ? (
    <div className="toolbar">
      <button className="button">
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <button className="button">
        <FontAwesomeIcon icon={faUpDownLeftRight} />
      </button>
      <button className="button" onClick={handleRemove}>
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </div>
  ) : (
    <></>
  );
};

export default Toolbar;
