import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import Block from "../../../types/Editor/Block";
import "./Treeview.scss";
import {
  Accordion,
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import arrow from "../../../assets/images/arrow-down-right.svg";

import { map } from "../../Components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPray } from "@fortawesome/free-solid-svg-icons";

interface TreeProps {
  blocks: Block[];
}

const getComponentData = (block: Block) => {
  return map.find((item) => item.name == block.component);
};

const InnerTree = ({ blocks }: TreeProps) => {
  return (
    <Accordion className="tree settings no-right-padding">
      {blocks.map((block, index) => (
        <Accordion.Item eventKey={block.uuid} className="no-last-bottom-border">
          <Accordion.Header>
            <Container>
              <Row>
                <Col>
                  <img className="arrow-icon" src={arrow} />
                  <span className="tree-item">
                    {getComponentData(block)?.label}
                  </span>
                </Col>
                <Col>
                  <ButtonGroup size="sm" className="buttons">
                    <Button variant="transparent">
                      <FontAwesomeIcon icon={faPray} />
                    </Button>
                    <Button variant="transparent">
                      <FontAwesomeIcon icon={faPray} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Container>
          </Accordion.Header>
          {block.children?.length ? (
            <Accordion.Body className="no-right-padding">
              <InnerTree blocks={block.children ?? []} />
            </Accordion.Body>
          ) : (
            <></>
          )}
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
const Treeview = () => {
  const { content } = useSelector((state: RootState) => state.editor);
  const body = content?.body;

  return (
    <div className="tree-wrapper">
      <InnerTree blocks={body ?? []} />
    </div>
  );
};

export default Treeview;
