import React from "react";
import { FormGroup, Form } from "react-bootstrap";
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";

interface CheckboxProps<
  TFieldValues extends FieldValues,
  TFieldPath extends FieldPath<TFieldValues>
> {
  name: TFieldPath;
  label: string;
}

const Checkbox = <
  TFieldValues extends FieldValues,
  TFieldPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
}: CheckboxProps<TFieldValues, TFieldPath>) => {
  const {
    formState: { errors },
  } = useFormContext<TFieldValues>();

  return (
    <Controller<TFieldValues, TFieldPath>
      name={name}
      render={({ field }) => (
        <FormGroup>
          <Form.Check
            {...field}
            isInvalid={!!errors[name]?.message}
            label={label}
            defaultChecked={field.value}
          />
          {errors[name] && (
            <Form.Control.Feedback type="invalid">
              <>{errors[name]?.message}</>
            </Form.Control.Feedback>
          )}
        </FormGroup>
      )}
    />
  );
};

export default Checkbox;
