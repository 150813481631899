import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Admin/Sidebar/Sidebar";
import { useByDomainQuery } from "../services/WebsiteService";

const Admin = () => {
  const { data: websiteResponse } = useByDomainQuery(
    document.location.hostname
  );
  const website = websiteResponse?.data;

  return (
    <>
      {website && (
        <Container fluid>
          <Row>
            <Col md={2} className="mt-3">
              <Sidebar />
            </Col>
            <Col className="mt-3">
              <Outlet />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Admin;
