import { Form, FormControl, FormGroup } from "react-bootstrap";
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";

interface InputProps<
  TFieldValues extends FieldValues,
  TFieldPath extends FieldPath<TFieldValues>
> {
  name: TFieldPath;
  label: string;
  type: "text";
}

const Input = <
  TFieldValues extends FieldValues,
  TFieldPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  type,
}: InputProps<TFieldValues, TFieldPath>) => {
  const {
    formState: { errors },
  } = useFormContext<TFieldValues>();

  return (
    <Controller<TFieldValues, TFieldPath>
      name={name}
      render={({ field }) => (
        <FormGroup>
          <Form.Label>{label}</Form.Label>
          <FormControl
            type={type}
            {...field}
            isInvalid={!!errors[name]?.message}
          />
          {errors[name] && (
            <Form.Control.Feedback type="invalid">
              <>{errors[name]?.message}</>
            </Form.Control.Feedback>
          )}
          {/* <Form.Control.Feedback type="valid">{success}</Form.Control.Feedback>  */}
        </FormGroup>
      )}
    />
  );
};

export default Input;
