import { CSSProperties } from "react";
import useEditor from "../../../hooks/useEditor";
import Block, { BaseBlock } from "../../../types/Editor/Block";
import Toolbar from "../../Editor/Toolbar/Toolbar";
import useStyles from "../../../hooks/useStyles";

export interface ParagrapsProps extends BaseBlock {
  component: "paragraph";
  text?: string;
}

const Paragraph = (props: ParagrapsProps): JSX.Element => {
  const newProps = useEditor(props);
  const { children } = newProps;
  const style = useStyles(newProps as any);
  return (
    <p {...newProps} style={style}>
      <Toolbar {...newProps} />
      {children}
    </p>
  );
};
export default Paragraph;
