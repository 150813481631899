import { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import constants from "../../Constants";
import { ShowErrorAlert } from "../../functions/showErrorAlert";
import { FormProvider, useForm } from "react-hook-form";

import Form from "./Form";
import AppContext from "../../context/AppContext";
import {
  useGetTaxonomyTypeQuery,
  useUpdateTaxonomyTypeMutation,
} from "../../services/TaxonomyTypeService";
import usePermissions from "../../hooks/usePermissions";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { TaxonomyType } from "../../types/TaxonomyType";

interface ErrorResponse {
  message: string;
  errors: Record<string, string[]>;
}

const Update = (): JSX.Element => {
  const navigate = useNavigate();
  const { taxonomyTypeId } = useParams();
  const { website } = useContext(AppContext);

  const { currentData: taxonomyTypeRepsponse } = useGetTaxonomyTypeQuery(
    Number(taxonomyTypeId)
  );

  const taxonomyType = taxonomyTypeRepsponse?.data;
  const methods = useForm<TaxonomyType>();

  useEffect(() => {
    methods.reset(taxonomyType);
  }, [taxonomyType, methods]);

  const [updateTaxonomyType] = useUpdateTaxonomyTypeMutation();

  const { can } = usePermissions(website);
  const permissions = constants.permissions;

  const handleOnSubmit = async (taxonomyType: TaxonomyType) => {
    if (website) {
      try {
        await updateTaxonomyType(taxonomyType).unwrap();

        Swal.fire({ icon: "success", title: "Zapisano!" });
        navigate(`/admin/taxonomy-types`);
      } catch (e) {
        const error = e as FetchBaseQueryError;
        if (error.status === 422) {
          Object.entries<Record<any, any>>(
            (error.data as ErrorResponse).errors
          ).map(([item, errors]) =>
            methods.setError(item as any, {
              message: errors.join(","),
            })
          );
        } else {
          ShowErrorAlert(
            "Ups",
            "Coś poszło nie tak. Spróbuj ponownie za chwilę."
          );
        }
      }
    }
  };

  return can(permissions.TAXONOMYTYPE_UPDATE) ? (
    <Container fluid>
      <Row>
        <Col>
          <h3 className="mb-2">Edytuj {taxonomyType?.name}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(handleOnSubmit)} />
          </FormProvider>
        </Col>
      </Row>
    </Container>
  ) : (
    <Navigate to="/admin/forbidden" />
  );
};

export default Update;
