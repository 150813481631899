import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import useOnChange from "../../../../hooks/useOnChange";
import FileManagerModal from "../../../FileManager/FileManagerModal";

const Slides = (): JSX.Element => {
  const [showFileManager, setShowFileManager] = useState<boolean>(false);
  const { onChange, component, style } = useOnChange();

  const handleOnClick = () => {
    setShowFileManager(true);
  };
  const handleClear = () => {
    onChange({ src: "https://via.placeholder.com/300x300.png" });
  };

  const handleOnCloseFileManager = () => {
    setShowFileManager(false);
  };

  const handleOnSelectFile = (e: any) => {
    onChange({ src: e[0].url });
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Tytuł</Form.Label>
            <Form.Control size="sm" />
          </Form.Group>
          <Form.Group>
            <Form.Label>Podtytuł</Form.Label>
            <Form.Control size="sm" />
          </Form.Group>
        </Col>
      </Row>
      <>
        <Row>
          <Col md="12">
            <Form.Label>Zdjęcie</Form.Label>
          </Col>
          <Col>
            <ButtonGroup>
              <Button variant="light" onClick={handleOnClick}>
                Wybierz
              </Button>
              <Button variant="light" onClick={handleClear}>
                <FontAwesomeIcon icon={faEraser} />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        {showFileManager && (
          <FileManagerModal
            onClose={handleOnCloseFileManager}
            onSelect={handleOnSelectFile}
            multiple={false}
          />
        )}
      </>
    </>
  );
};

export default Slides;
