import { ListParams } from "../types/Api/ListParams";
import { SingleItemResponse, ListResponse } from "../types/Api/Response";
import { Taxonomy } from "../types/Taxonomy";
import Api from "./Api";

export const TaxonomyService = Api.injectEndpoints({
  endpoints: (build) => ({
    getTaxonomy: build.query<SingleItemResponse<Taxonomy>, number>({
      query: (id) => `/private/taxonomies/${id}`,
    }),

    publicGetTaxonomy: build.query<SingleItemResponse<Taxonomy>, number>({
      query: (id) => `/public/taxonomies/${id}`,
    }),

    publicTaxonomyContents: build.query<SingleItemResponse<Taxonomy>, number>({
      query: (id) => `/public/taxonomies/${id}/contents`,
    }),

    publicTaxonomies: build.query<SingleItemResponse<Taxonomy>, number>({
      query: (id) => `/public/taxonomies/${id}/nested-taxonomies`,
    }),

    createTaxonomy: build.mutation<
      SingleItemResponse<Taxonomy>,
      { websiteId: number; taxonomyTypeId: number; taxonomy: Taxonomy }
    >({
      query: ({ websiteId, taxonomyTypeId, taxonomy }) => ({
        url: `/private/websites/${websiteId}/taxonomy-types/${taxonomyTypeId}/taxonomies`,
        body: taxonomy,
        method: "POST",
      }),
    }),

    listTaxonomies: build.query<
      ListResponse<Taxonomy>,
      { websiteId: number; taxonomyTypeId: number; params: ListParams }
    >({
      query: ({ websiteId, taxonomyTypeId, params }) => ({
        url: `/private/websites/${websiteId}/taxonomy-types/${taxonomyTypeId}/taxonomies`,
        params: params,
      }),

      providesTags: (result: any, error: any, arg: any) => [
        { type: "TAXONOMY", arg },
        "TAXONOMY",
      ],
    }),

    updateTaxonomy: build.mutation<SingleItemResponse<Taxonomy>, Taxonomy>({
      query: (taxonomy) => ({
        url: `/private/taxonomies/${taxonomy.id}`,
        body: taxonomy,
        method: "PATCH",
      }),
    }),
    removeTaxonomy: build.mutation<SingleItemResponse<Taxonomy>, Taxonomy>({
      query: (taxonomy) => ({
        url: `/private/taxonomies/${taxonomy.id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result: any, error: any, arg: Taxonomy) => [
        { type: "TAXONOMY", id: arg.taxonomy_type_id },
      ],
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreateTaxonomyMutation,
  useGetTaxonomyQuery,
  useLazyGetTaxonomyQuery,
  useLazyPublicGetTaxonomyQuery,
  useLazyPublicTaxonomiesQuery,
  useLazyPublicTaxonomyContentsQuery,
  usePrefetch,
  usePublicGetTaxonomyQuery,
  usePublicTaxonomiesQuery,
  usePublicTaxonomyContentsQuery,
  useRemoveTaxonomyMutation,
  useUpdateTaxonomyMutation,
  useLazyListTaxonomiesQuery,
  useListTaxonomiesQuery,
} = TaxonomyService;
