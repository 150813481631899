import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import useOnChange from "../../../../hooks/useOnChange";
import useStyles from "../../../../hooks/useStyles";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faMinimize,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

const Border = (): JSX.Element => {
  const { onChange, component, style } = useOnChange();

  const handleOnChange = (e: any) => {
    const style = { [e.target.name]: Number(e.target.value) };
    onChange({ style: style });
  };

  return (
    <>
      <Row>
        <Col>
          <InputGroup size="sm">
            <Form.Control
              value={style?.borderTop ?? 0}
              onChange={handleOnChange}
              type="number"
              name="borderTop"
              className="text-center"
            />
            <Form.Control
              value={style?.borderRight ?? 0}
              onChange={handleOnChange}
              type="number"
              name="borderRight"
              className="text-center"
            />
            <Form.Control
              value={style?.borderBottom ?? 0}
              onChange={handleOnChange}
              type="number"
              name="borderBottom"
              className="text-center"
            />
            <Form.Control
              value={style?.borderLeft ?? 0}
              onChange={handleOnChange}
              type="number"
              name="borderLeft"
              className="text-center"
            />
            <InputGroup.Text>px</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>Kolor</Form.Label>
          <Form.Control />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>Styl obramowania</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonGroup size="sm">
            <Button variant="light">
              <FontAwesomeIcon icon={faEllipsis} />
            </Button>
            <Button variant="light">
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </>
  );
};

export default Border;
