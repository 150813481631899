import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import useOnChange from "../../../../hooks/useOnChange";
import useStyles from "../../../../hooks/useStyles";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faItalic,
  faTextHeight,
  faUnderline,
} from "@fortawesome/free-solid-svg-icons";

const Font = (): JSX.Element => {
  const { onChange, component, style } = useOnChange();

  return (
    <>
      <Row>
        <Col>
          <InputGroup size="sm">
            <Form.Control type="number" name="marginTop" />
            <InputGroup.Text>px</InputGroup.Text>
            <Form.Select>
              <option></option>
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <ButtonGroup size="sm">
            <Button variant="light">
              <FontAwesomeIcon icon={faItalic} />
            </Button>
            <Button variant="light">
              <FontAwesomeIcon icon={faBold} />
            </Button>
            <Button variant="light">
              <FontAwesomeIcon icon={faUnderline} />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup size="sm">
            <Button variant="light">
              <FontAwesomeIcon icon={faAlignLeft} />
            </Button>
            <Button variant="light">
              <FontAwesomeIcon icon={faAlignCenter} />
            </Button>
            <Button variant="light">
              <FontAwesomeIcon icon={faAlignRight} />
            </Button>
            <Button variant="light">
              <FontAwesomeIcon icon={faAlignJustify} />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <InputGroup size="sm">
          <InputGroup.Text>
            <FontAwesomeIcon icon={faTextHeight} />
          </InputGroup.Text>
          <Form.Control />
          <InputGroup.Text>px</InputGroup.Text>
        </InputGroup>
      </Row>
    </>
  );
};

export default Font;
