const styles = {
  menu: {
    default: {
      listStyle: "none",
      //display: 'none',
    },
    horizontal: {
      default: {
        display: "flex",
      },
    },
    item: {
      default: {
        padding: "10px 5px",
      },
    },
  },
};

export default styles;
