import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useContext, useEffect } from "react";

import Block, { StyleSize } from "../types/Editor/Block";
import useViewport from "./useViewport";
import lodash from "lodash";
import { setSelectedComponent } from "../store/reducers/Editor";
import { serialize } from "./useEditor";

const useOnChange = () => {
  const { selectedComponent, resolution } = useSelector(
    (state: RootState) => state.editor
  );

  const dispatch = useDispatch();

  const getStyleSize = (): StyleSize => {
    let size: StyleSize;

    if (resolution === "desktop") {
      size = "lg";
    } else if (resolution === "tablet") {
      size = "md";
    } else {
      size = "xs";
    }
    return size;
  };

  const onComponentChange = (e: any) => {
    const style = e.style;
    const toChange = lodash.merge(serialize(selectedComponent), e, {
      style: {
        [getStyleSize()]: style,
      },
    });

    dispatch(setSelectedComponent(toChange));
  };

  const styleSize = getStyleSize();
  const style = selectedComponent?.style?.[styleSize];

  return {
    component: selectedComponent,
    onChange: onComponentChange,
    styleSize: styleSize,
    style: style,
  };
};

export default useOnChange;
