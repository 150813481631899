import { Col } from "react-bootstrap";
import useEditor from "../../../hooks/useEditor";
import { BaseBlock } from "../../../types/Editor/Block";
import Toolbar from "../../Editor/Toolbar/Toolbar";
import useStyles from "../../../hooks/useStyles";

export interface ColumnProps extends BaseBlock {
  component: "column";
}

const Column = (props: ColumnProps) => {
  const newProps = useEditor(props);
  const { children } = newProps;
  const style = useStyles(props);

  return (
    <Col {...newProps} style={style}>
      <Toolbar {...newProps} />
      {children}
    </Col>
  );
};

export default Column;
