import { useNavigate, useParams } from "react-router-dom";
import "./EditorSwitch.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export interface EditorSwitchProps {
  layoutId?: number;
}

const EditorSwitch = (props: EditorSwitchProps) => {
  const { layoutId } = props;
  const { layoutId: currentLayoutId } = useParams();
  const { editortype } = useSelector((state: RootState) => state.editor);
  // console.log(editortype);

  const navigate = useNavigate();

  const handleOnClick = () => {
    //   Swal.fire({
    //     icon: "question",
    //     showConfirmButton: true,
    //     showCancelButton: true,
    //     showDenyButton: true,
    //     title: `Czy zapisać zmiany?`,
    //     text: `Za chwilę zostaniesz przekierowany do edytora szablonu "${layout.title}". Naciśnij "Zapisz" by nie utracić wprowadzonych zmian.`,
    //     confirmButtonText: "Zapisz",
    //     denyButtonText: "Nie zapisuj",
    //     cancelButtonText: "Anuluj",
    //   }).then((response) => {
    //     if (response.isConfirmed) {
    //       const data = {
    //         ...content,
    //         body: content.body[0].children,
    //       };
    //       dispatch(
    //         data.id
    //           ? update(data.id, data)
    //           : create(website.id, contentType.id, data)
    //       )
    //         .then((response) => {
    //           Swal.fire({ icon: "success", title: "Zapisano!" });
    //           navigate(`/editor/layout/${layout.id}`);
    //         })
    //         .catch((errors) => {
    //           ShowErrorAlert(
    //             "Ups",
    //             "Coś poszło nie tak. Spróbuj ponownie za chwilę."
    //           );
    //           dispatch(setErrors(errors.response.data.errors));
    //         });
    //     }
    //     if (response.isDenied) {
    //       navigate(`/editor/layout/${layout.id}`);
    //     }
    //     if (response.isDismissed) {
    //       console.log("Anuluj");
    //     }
    //   });
  };
  return layoutId !== Number(currentLayoutId) && editortype ? (
    <div className="toolbar editor-switch">
      <div className="layout-editor-switch" onClick={handleOnClick}>
        Edytuj szablon
      </div>
    </div>
  ) : (
    <></>
  );
};

export default EditorSwitch;
