import { Col, Form, InputGroup, Row } from "react-bootstrap";
import useOnChange from "../../../../hooks/useOnChange";
import useStyles from "../../../../hooks/useStyles";
import { useEffect } from "react";

const Padding = (): JSX.Element => {
  const { onChange, component, style } = useOnChange();

  const handleOnChange = (e: any) => {
    const style = { [e.target.name]: Number(e.target.value) };
    onChange({ style: style });
  };

  return (
    <Row>
      <Col>
        <InputGroup size="sm">
          <Form.Control
            value={style?.paddingTop ?? 0}
            onChange={handleOnChange}
            type="number"
            name="paddingTop"
            className="text-center"
          />
          <Form.Control
            value={style?.paddingRight ?? 0}
            onChange={handleOnChange}
            type="number"
            name="paddingRight"
            className="text-center"
          />
          <Form.Control
            value={style?.paddingBottom ?? 0}
            onChange={handleOnChange}
            type="number"
            name="paddingBottom"
            className="text-center"
          />
          <Form.Control
            value={style?.paddingLeft ?? 0}
            onChange={handleOnChange}
            type="number"
            name="paddingLeft"
            className="text-center"
          />
          <InputGroup.Text>px</InputGroup.Text>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default Padding;
