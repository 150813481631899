import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { map } from "../components/Components";

import WrapperContext from "../context/WrapperContext";
import {
  setContent,
  setLayout,
  setSelectedComponent,
} from "../store/reducers/Editor";
import { RootState } from "../store/store";
import Block from "../types/Editor/Block";
import { useDynamicValue } from "./useDynamicValue";
import AppContext from "../context/AppContext";
import { Content } from "../types/Content";

import lodash from "lodash";

export const serialize = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export const generateKeys = (block: Block): Block => {
  return {
    ...block,
    uuid: uuidv4(),
    children: block.children?.map((child: Block) => generateKeys(child)),
  };
};

const useEditor = (props: Block) => {
  const [block, setBlock] = useState<Block>(props);
  const { callback } = props;

  const { refresh } = useContext(AppContext);

  const dispatch = useDispatch();

  const [value] = useDynamicValue((block as any).text);
  const {
    editmode: globalEditMode,
    selectedComponent,
    editortype,
    content,
    layout,
  } = useSelector((state: RootState) => state.editor);

  const { type } = useContext(WrapperContext);

  const editmode = editortype === type && globalEditMode;

  const [locked, setLocked] = useState<boolean>(true);

  const componentData = map.find((cd) => cd.name === block.component);

  useEffect(() => {
    if (selectedComponent?.uuid === block.uuid) {
      setBlock(selectedComponent);
    }
  }, [selectedComponent]);

  useEffect(() => {
    if (editortype === "content") {
      if (block.component === "content") {
        dispatch(
          setContent(
            serialize({
              ...content,
              body: block.children,
            }) as Content
          )
        );
      }
    }
    if (editortype === "layout") {
      if (block.component === "layout") {
        if (layout?.id === block.layout.id) {
          dispatch(
            setLayout(
              serialize({
                ...layout,
                body: block.children,
              })
            )
          );
        }
      }
    }
    if (callback) {
      callback(block);
    }
  }, [block]);

  useEffect(() => {
    setBlock(props);
  }, [refresh]);

  const handleOnChange = (e: any) => {
    const newBlock = {
      ...block,
      children: block.children?.map((child) =>
        child.uuid === e.uuid ? e : child
      ),
    };
    setBlock(newBlock);
  };

  const handleOnInsertBefore = (e: Block, marker: boolean = true) => {
    if (block.children && componentData) {
      let children = block.children.filter(
        (children) => children.component !== "marker"
      );
      if (
        (Array.isArray(componentData.dropable) &&
          componentData.dropable.indexOf(selectedComponent?.component ?? "") >=
            0) ||
        componentData.dropable === true
      ) {
        const element: Block = generateKeys(
          marker
            ? {
                component: "marker",
                uuid: "",
              }
            : e
        );

        const index = children.findIndex((child) => child.uuid === e.uuid);
        children = [
          ...children.slice(0, index),
          element,
          ...children.slice(index),
        ];
      }

      onComponentChange({
        children: children,
      });
    }
  };

  const handleOnInsertAfter = (e: Block, marker: boolean = true) => {
    if (block.children && componentData) {
      let children = block.children.filter(
        (children) => children.component !== "marker"
      );
      if (
        (Array.isArray(componentData.dropable) &&
          componentData.dropable.indexOf(selectedComponent?.component ?? "") >=
            0) ||
        componentData.dropable === true
      ) {
        const element: Block = generateKeys(
          marker
            ? {
                component: "marker",
                uuid: "",
              }
            : e
        );

        const index = children.findIndex((child) => child.uuid === e.uuid) + 1;
        children = [
          ...children.slice(0, index),
          element,
          ...children.slice(index),
        ];
      }

      onComponentChange({
        children: children,
      });
    }
  };

  const handleOnDelete = (e: Block) => {
    onComponentChange({
      children: block.children?.filter((child) => child.uuid !== e.uuid),
    });
  };

  const children = block.children?.length
    ? block.children.map((child: Block) => {
        const componentData = map.find((item) => item.name === child.component);
        const Component = componentData?.component;
        const childProps = {
          ...child,
          insertBefore: handleOnInsertBefore,
          insertAfter: handleOnInsertAfter,
          delete: handleOnDelete,
          callback: handleOnChange,
        };
        if (Component) {
          return <Component {...childProps} key={childProps.uuid} />;
        } else {
          return <></>;
        }
      })
    : [<>{value}</>];

  const onDragStart = (e: any) => {
    setTimeout(() => {
      onComponentChange({ dragged: true });
    });

    e.stopPropagation();
    const serializedBlock = JSON.parse(JSON.stringify(block));
    dispatch(setSelectedComponent(serializedBlock));
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (locked) {
      setTimeout(() => {
        setLocked(false);
      }, 500);
    } else {
      if (componentData?.dropable) {
        let children =
          block.children?.filter((child) => child.component !== "marker") ?? [];
        if (
          (Array.isArray(componentData.dropable) &&
            componentData.dropable.indexOf(
              selectedComponent?.component ?? ""
            ) >= 0) ||
          componentData.dropable === true
        ) {
          const marker: Block = generateKeys({
            component: "marker",
            uuid: "",
          });

          children =
            e.nativeEvent.offsetY > e.nativeEvent.target.clientHeight / 2
              ? [...children, marker]
              : [marker, ...children];
        }

        onComponentChange({
          children: children,
        });
      } else {
        if (e.nativeEvent.offsetY > e.nativeEvent.target.clientHeight / 2) {
          props.insertAfter(block, true);
        } else {
          props.insertBefore(block, true);
        }
      }
    }
  };

  const onDragEnd = (e: any) => {
    onComponentChange({
      dragged: false,
    });

    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.dropEffect === "copy") {
      props.delete(block);
    }
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    if (componentData?.dropable) {
      e.stopPropagation();
      const newBlock = generateKeys(selectedComponent as Block);
      onComponentChange({
        children: block.children?.map((child) =>
          child.component === "marker" ? newBlock : child
        ),
      });
      dispatch(setSelectedComponent(newBlock));
    }
  };

  const onDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setLocked(true);

    onComponentChange({
      children: block.children?.filter(
        (child: Block) => child.component !== "marker"
      ),
    });
  };

  const onComponentChange = (params: any) => {
    const newBlock = { ...block, ...params };
    setBlock(newBlock);
  };

  const onClick = (e: any) => {
    e.stopPropagation();
    dispatch(setSelectedComponent(serialize(block)));
  };

  const onDoubleClick = (e: any) => {
    e.stopPropagation();
  };
  return {
    ...block,
    children: children,
    onDragStart: editmode ? onDragStart : undefined,
    onDragOver: editmode ? onDragOver : undefined,
    onDragEnd: editmode ? onDragEnd : undefined,
    onDoubleClick: editmode ? onDoubleClick : undefined,
    onDrop: editmode ? onDrop : undefined,
    onDragLeave: editmode ? onDragLeave : undefined,
    onClick: editmode ? onClick : undefined,
    dragged: String(Boolean(block.dragged)),
    draggable: editmode,
    editmode: String(Boolean(editmode)),
  };
};

export default useEditor;
