import { useEffect, useState } from "react";
import { useFrame } from "react-frame-component";

const useViewport = () => {
  const { window: iframeWindow } = useFrame();
  const mWindow = iframeWindow ? iframeWindow : window;

  const [viewport, setViewport] = useState<{ width: number; height: number }>({
    width: mWindow.innerWidth,
    height: mWindow.innerHeight,
  });

  const handleViewportUpdate = () => {
    setViewport({
      width: mWindow.innerWidth,
      height: mWindow.innerHeight,
    });
  };

  useEffect(() => {
    mWindow.addEventListener("resize", handleViewportUpdate, { passive: true });

    return () => {
      mWindow.removeEventListener("resize", handleViewportUpdate);
    };
  }, []);

  return viewport;
};

export default useViewport;
