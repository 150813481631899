import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import constants from "../../../Constants";
import "./Sidebar.scss";

import { useContentTypeListQuery } from "../../../services/ContentTypeService";
import { useTaxonomyTypeListQuery } from "../../../services/TaxonomyTypeService";
import usePermissions from "../../../hooks/usePermissions";

import AppContext from "../../../context/AppContext";
import { DefaultListParams } from "../../../types/Api/ListParams";

interface SidebarProps {}
const Sidebar = (props: SidebarProps) => {
  const { website } = useContext(AppContext);
  const location = useLocation();
  const [showMore, setShowMore] = useState(false);

  const { data: contentTypesResponse } = useContentTypeListQuery({
    websiteId: website?.id,
    params: DefaultListParams,
  });

  const { data: taxonomyTypesResponse } = useTaxonomyTypeListQuery({
    websiteId: website?.id,
    params: DefaultListParams,
  });

  const taxonomyTypes = taxonomyTypesResponse?.data;
  const contentTypes = contentTypesResponse?.data;

  const { can } = usePermissions(website);
  const permissions = constants.permissions;

  const active = (link: string) => {
    return location.pathname.indexOf(link) === 0;
  };

  useEffect(() => {
    setShowMore(active("/admin/settings"));
  }, []);

  return (
    <div className="Sidebar">
      <nav className="navigation">
        <ul>
          <li className={active("/admin/dashboard") ? "active" : ""}>
            <Link to="/admin/dashboard">Dashboard</Link>
          </li>
        </ul>
        {can(permissions.CONTENT_VIEWANY) && (
          <>
            <div className="separator" />
            <ul>
              {contentTypes?.map((contentType, index) => {
                return (
                  <li
                    key={index}
                    className={
                      active("/admin/content-types/" + contentType.id)
                        ? "active"
                        : ""
                    }
                  >
                    <Link
                      to={`/admin/content-types/${contentType.id}/contents`}
                    >
                      {contentType.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {can(permissions.TAXONOMY_VIEWANY) && (
          <>
            <div className="separator" />
            <ul>
              {taxonomyTypes?.map((taxonomyType, index) => {
                return (
                  <li
                    key={index}
                    className={
                      active("/admin/taxonomy-types/" + taxonomyType.id)
                        ? "active"
                        : ""
                    }
                  >
                    <Link
                      to={`/admin/taxonomy-types/${taxonomyType.id}/taxonomies`}
                    >
                      {taxonomyType.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {can(permissions.FILE_VIEWANY) && (
          <>
            <div className="separator" />
            <ul>
              <li className={active("/admin/file-manager") ? "active" : ""}>
                <Link to="/admin/file-manager">Manager plików</Link>
              </li>
            </ul>
          </>
        )}

        {can(permissions.LAYOUT_VIEWANY) && (
          <>
            <div className="separator" />
            <ul>
              <li className={active("/admin/layouts") ? "active" : ""}>
                <Link to="/admin/layouts">Szablony</Link>
              </li>
            </ul>
          </>
        )}
        <div className="separator" />
        <ul>
          <li className={active("/admin/settings") ? "active" : ""}>
            <Link to="#" onClick={() => setShowMore(!showMore)}>
              Zaawansowane
              <FontAwesomeIcon
                icon={showMore ? faChevronUp : faChevronDown}
                className="arrow"
              />
            </Link>
            {showMore && (
              <ul className="nested">
                {can(permissions.CONTENTTYPE_VIEWANY) && (
                  <li
                    className={active("/admin/content-types") ? "active" : ""}
                  >
                    <Link to="/admin/content-types">Typy treści</Link>
                  </li>
                )}
                {can(permissions.TAXONOMYTYPE_VIEWANY) && (
                  <li
                    className={active("/admin/taxonomy-types") ? "active" : ""}
                  >
                    <Link to="/admin/taxonomy-types">Typy taksonomii</Link>
                  </li>
                )}
                {can(permissions.METAGROUP_VIEWANY) && (
                  <li className={active("/admin/meta-groups") ? "active" : ""}>
                    <Link to="/admin/meta-groups">Własne pola</Link>
                  </li>
                )}
                <li className={active("/admin/website") ? "active" : ""}>
                  <Link to="/admin/website">Ustawienia</Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
