import { Container as BootstrapContainer, Row } from "react-bootstrap";

import useEditor from "../../../hooks/useEditor";
import { BaseBlock } from "../../../types/Editor/Block";
import "./Container.scss";
import Toolbar from "../../Editor/Toolbar/Toolbar";
import useStyles from "../../../hooks/useStyles";

export interface ContainerProps extends BaseBlock {
  component: "container";
}

const Container = (props: ContainerProps) => {
  const newProps = useEditor(props);
  const { children } = newProps;
  const style = useStyles(props);

  return (
    <BootstrapContainer {...newProps} fluid={true} style={style}>
      <Toolbar {...newProps} />
      <Row className="item-row">{children}</Row>
    </BootstrapContainer>
  );
};
export default Container;
