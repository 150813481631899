import { useMeQuery } from "../services/AuthService";
import { Website } from "../types/Website";

const usePermissions = (website: Website | undefined) => {
  const { data } = useMeQuery(null);
  const user = data?.data;

  const can = (permission: string) => {
    if (!website) return true;
    return (
      user?.permissions
        .find((permissions) => permissions.website_id === website?.id)
        ?.permissions?.find(
          (websitePermission) => websitePermission === permission
        ) !== undefined
    );
  };

  return {
    can: can,
  };
};

export default usePermissions;
