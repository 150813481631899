import { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Row, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import constants from "../../Constants";

import DataTable from "../../components/Admin/DataTable/DataTable";
import { useGetTaxonomyTypeQuery } from "../../services/TaxonomyTypeService";
import {
  useListTaxonomiesQuery,
  useRemoveTaxonomyMutation,
} from "../../services/TaxonomiesService";
import { skipToken } from "@reduxjs/toolkit/query";
import usePermissions from "../../hooks/usePermissions";
import { Taxonomy } from "../../types/Taxonomy";
import AppContext from "../../context/AppContext";
import { DefaultListParams, ListParams } from "../../types/Api/ListParams";

const Taxonomies = (): JSX.Element => {
  const [removeTaxonomy] = useRemoveTaxonomyMutation();
  const { taxonomyTypeId } = useParams();
  const { website } = useContext(AppContext);
  const { currentData: taxonomyTypeRepsponse } = useGetTaxonomyTypeQuery(
    Number(taxonomyTypeId)
  );

  const [params, setParams] = useState<ListParams>(DefaultListParams);

  const taxonomyType = taxonomyTypeRepsponse?.data;

  const { currentData: taxonomiesResponse, isFetching } =
    useListTaxonomiesQuery(
      website && taxonomyType
        ? {
            websiteId: website.id,
            taxonomyTypeId: taxonomyType.id,
            params: params,
          }
        : skipToken
    );

  const rows = taxonomiesResponse?.data ?? [];
  const meta = taxonomiesResponse?.meta ?? {
    total: 0,
    per_page: 0,
  };

  const navigate = useNavigate();

  const { can } = usePermissions(website);
  const permissions = constants.permissions;

  const columns = [
    { field: "title", headerName: "Tytuł", flex: 1 },
    {
      field: "actions",
      headerName: "Akcje",
      width: 150,
      renderCell: (params: any) => {
        return (
          <ButtonGroup size="sm">
            {can(permissions.TAXONOMY_UPDATE) && (
              <Button
                variant="warning"
                onClick={() =>
                  navigate(
                    `/admin/taxonomy-types/${taxonomyTypeId}/taxonomies/${params.row.id}`
                  )
                }
              >
                Edytuj
              </Button>
            )}
            {can(permissions.TAXONOMYTYPE_DELETE) && (
              <Button
                variant="danger"
                onClick={() => handleOnDelete(params.row)}
              >
                Usuń
              </Button>
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params, loading: true });
  };

  useEffect(() => {
    // dispatch(all(website.id, taxonomyTypeId, params)).then(() => {
    //   setParams({ ...params, loading: false });
    // });
  }, [
    taxonomyTypeId,
    params.page,
    params.per_page,
    params.sort,
    params.direction,
  ]);

  const handleOnDelete = (taxonomy: Taxonomy) => {
    Swal.fire({
      title: "Czy jesteś pewien?",
      icon: "question",
      confirmButtonText: "Usuń",
      cancelButtonText: "Anuluj",
      showCancelButton: true,
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        removeTaxonomy(taxonomy);
      }
    });
  };

  return can(permissions.TAXONOMY_VIEWANY) ? (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h3 className="mb-2">{taxonomyType?.name}</h3>
          </Col>
          <Col className="text-right">
            {can(permissions.TAXONOMY_CREATE) && (
              <Link
                to={`/admin/taxonomy-types/${taxonomyTypeId}/taxonomies/new`}
              >
                <Button variant="primary">Dodaj</Button>
              </Link>
            )}
          </Col>
        </Row>
      </Container>
      <DataTable
        onParamsChange={onParamsChange}
        params={{ ...params, loading: isFetching }}
        rows={rows}
        meta={meta}
        columns={columns}
      />
    </>
  ) : (
    <Navigate to="/admin/forbidden" />
  );
};

export default Taxonomies;
