import Background from "./Background/Background";
import BlockSize from "./BlockSize/BlockSize";
import Border from "./Border/Border";
import BorderRadius from "./BorderRadius/BorderRadius";
import BoxShadow from "./BoxShadow/BoxShadow";
import ColumnWidth from "./ColumnWidth/ColumnWidth";
import Font from "./Font/Font";
import Href from "./Href/Href";
import Margin from "./Margin/Margin";
import Menu from "./Menu/Menu";
import Padding from "./Padding/Padding";
import Size from "./Size/Size";
import SliderSettings from "./SliderSettings/SliderSettings";
import Slides from "./Slides/Slides";
import Src from "./Src/Src";

export interface SettingMapItem {
  name: string;
  label: string;
  component: any;
}

export const map: SettingMapItem[] = [
  {
    name: "margin",
    label: "Marginesy wewnętrzne",
    component: Margin,
  },

  {
    name: "padding",
    label: "Marginesy zewnętrzne",
    component: Padding,
  },

  {
    name: "font",
    label: "Czcionka",
    component: Font,
  },

  {
    name: "blockSize",
    label: "Rozmiar",
    component: BlockSize,
  },

  {
    name: "background",
    label: "Tło",
    component: Background,
  },

  {
    name: "boxShadow",
    label: "Cień",
    component: BoxShadow,
  },

  {
    name: "columnWidth",
    label: "Szerokość kolumny",
    component: ColumnWidth,
  },

  {
    name: "src",
    label: "Źródło",
    component: Src,
  },
  {
    name: "sliderSettings",
    label: "Ustawienia slidera",
    component: SliderSettings,
  },
  {
    name: "slides",
    label: "Slajdy",
    component: Slides,
  },

  {
    name: "border",
    label: "Obramowanie",
    component: Border,
  },

  {
    name: "borderRadius",
    label: "Zaokrąglenie krawędzi",
    component: BorderRadius,
  },

  {
    name: "boxShadow",
    label: "Cień",
    component: BoxShadow,
  },
  {
    name: "menu",
    label: "Ustawienia menu",
    component: Menu,
  },

  {
    name: "size",
    label: "Rozmiar",
    component: Size,
  },

  {
    name: "href",
    label: "Link",
    component: Href,
  },
];
