import axios from "axios";
import { SingleItemResponse, ListResponse } from "../types/Api/Response";
import { Content } from "../types/Content";
import { Directory } from "../types/Directory";
import { FileType } from "../types/File";
import { MetaGroup } from "../types/MetaGroup";
import { OnlyId } from "../types/OnlyId";
import { Taxonomy } from "../types/Taxonomy";
import { Website } from "../types/Website";
import Api from "./Api";
import { store } from "../store/store";

interface CreateDirectoryParams {
  websiteId: number;
  directory: Directory;
}

interface UploadFilesParams {
  websiteId: number;
  files: any;
  directoryId?: number;
  onUploadProgress?: any;
}

export const FileManagerService = Api.injectEndpoints({
  endpoints: (build) => ({
    getDirectory: build.query<SingleItemResponse<Directory>, number>({
      query: (directory) => ({ url: `/private/directories/${directory}` }),
      providesTags: (result: any, error: any, arg: number) => [
        { type: "DIRECTORY", id: result.data.id },
        "DIRECTORY",
      ],
    }),

    createDirectory: build.mutation<
      SingleItemResponse<Directory>,
      CreateDirectoryParams
    >({
      query: ({ websiteId, directory }) => ({
        url: `/private/websites/${websiteId}/directories`,
        body: directory,
        method: "POST",
      }),
      invalidatesTags: (
        result: any,
        error: any,
        arg: CreateDirectoryParams
      ) => [{ type: "DIRECTORY", id: result.data.parent_id }],
    }),

    updateDirectory: build.mutation<SingleItemResponse<Directory>, Directory>({
      query: (directory) => ({
        url: `/private/directories/${directory.id}`,
        body: directory,
        method: "PATCH",
      }),
      invalidatesTags: (result: any, error: any, arg: Directory) => [
        { type: "DIRECTORY", id: result.data.parent_id },
      ],
    }),

    deleteDirectory: build.mutation<null, Directory>({
      query: ({ id }) => ({
        url: `/private/directories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result: any, error: any, arg: Directory) => [
        { type: "DIRECTORY", id: arg.parent_id },
      ],
    }),

    getFile: build.query<SingleItemResponse<FileType>, number>({
      query: (id) => ({ url: `/private/files/${id}` }),
      providesTags: (result: any, error: any, arg: number) => [
        { type: "FILE", id: arg },
        "FILE",
      ],
    }),

    uploadFiles: build.mutation<ListResponse<FileType>, UploadFilesParams>({
      queryFn: async ({ websiteId, files, directoryId, onUploadProgress }) => {
        try {
          const token = store.getState()?.token;

          const payload = new FormData();
          payload.append("directory_id", String(directoryId));

          files.forEach((file: any) => payload.append("files[]", file));

          const result = await axios.post(
            `${process.env.REACT_APP_API_URL}private/websites/${websiteId}/files`,
            payload,
            {
              onUploadProgress: onUploadProgress,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          return { data: result.data, error: undefined };
        } catch (axiosError: any) {
          let err = {
            status: axiosError.response?.status,
            data: axiosError.message,
          };

          throw axiosError;
        }
      },
      invalidatesTags: (result: any, error: any, arg: UploadFilesParams) => [
        { type: "DIRECTORY", id: arg.directoryId },
      ],
    }),

    updateFile: build.mutation<SingleItemResponse<FileType>, FileType>({
      query: (file) => ({
        url: `/private/files/${file.id}`,
        body: file,
        method: "PATCH",
      }),
      invalidatesTags: (result: any, error: any, arg: FileType) => [
        { type: "DIRECTORY", id: arg.directory_id },
      ],
    }),

    deleteFile: build.mutation<null, FileType>({
      query: ({ id }) => ({
        url: `/private/files/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result: any, error: any, arg: FileType) => {
        return [{ type: "DIRECTORY", id: arg.directory_id }];
      },
    }),
  }),
});

export const {
  useGetDirectoryQuery,
  useCreateDirectoryMutation,
  useDeleteDirectoryMutation,
  useDeleteFileMutation,
  useGetFileQuery,
  useLazyGetDirectoryQuery,
  useLazyGetFileQuery,
  useUpdateDirectoryMutation,
  useUpdateFileMutation,
  useUploadFilesMutation,
} = FileManagerService;
