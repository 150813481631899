import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fab } from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { Breadcrumb, Button, Container, ProgressBar } from "react-bootstrap";

import {
  useGetDirectoryQuery,
  useUploadFilesMutation,
} from "../../services/FileManagerService";

import { skipToken } from "@reduxjs/toolkit/query";
import { useByDomainQuery } from "../../services/WebsiteService";
import { Directory } from "../../types/Directory";
import "./FileManager.scss";
import FileComponent from "../../components/FileManager/File/File";
import DirectoryComponent from "../../components/FileManager/Directory/Directory";
import { FileType } from "../../types/File";
import FileManagerContext from "../../context/FileManagerContext";

interface FileManagerProps {
  onSelect?: any;
  onClose?: any;
  onSubmit?: any;
  multiple: boolean;
}

export type Item = FileType | Directory;

const FileManager = ({
  onSelect,
  onClose,
  onSubmit,
  multiple = false,
}: FileManagerProps) => {
  const { currentData: websiteResponse } = useByDomainQuery(
    document.location.hostname
  );
  const website = websiteResponse?.data;

  const [directoryId, setDirectoryId] = useState<number | undefined>(
    website?.directory_id
  );

  const { currentData: directoryResponse } = useGetDirectoryQuery(
    directoryId ?? skipToken
  );

  const [uploadFiles] = useUploadFilesMutation();
  const [newDirectory, setNewDirectory] = useState<boolean>(false);

  const directory = directoryResponse?.data;
  const files = directory?.files;
  const directories = directory?.directories;

  const items: Item[] = [...(files ?? []), ...(directories ?? [])];

  const [selected, setSelected] = useState<Item[]>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [progress, setProgress] = useState<number>(0);

  const ref = useRef<HTMLInputElement>(null);
  const [showProgress, setShowProgress] = useState(false);
  const [progressVariant, setProgressVariant] = useState("primary");
  const [progressLabel, setProgressLabel] = useState<string>("");

  const [parents, setParents] = useState<Directory[]>([]);

  useEffect(() => {
    onSelect(selected);
  }, [selected, onSelect]);

  useEffect(() => {
    if (directory?.id) {
      let parent = directory.parent;
      let items: Directory[] = [directory];
      while (parent) {
        items.push(parent);
        parent = parent.parent;
      }
      items = items.reverse();
      items[0] = { ...items[0], name: "Home" };
      if (items.length > 5) {
        items[1] = {
          name: "...",
          files: [],
          directories: [],
          type: "directory",
        };
      }
      setParents(items);
    }
  }, [directory]);

  const handleOnChangeDirectory = (directoryId: number) => {
    setDirectoryId(directoryId);
  };

  const handleCreateDirectory = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setNewDirectory(true);
    setShowMenu(false);
  };

  const handleUploadFiles = (e: any) => {
    if (ref.current) {
      ref.current.click();
      setShowMenu(false);
    }
  };

  const handleProgress = (e: any) => {
    const progress = Math.round((e.loaded / e.total) * 100);
    setProgress(progress);
    setProgressLabel(progress + "%");
    setShowProgress(true);
    setProgressVariant("primary");
  };

  const handleFilesChange = async (e: any) => {
    if (e.target.files.length > 0 && website) {
      try {
        const res = await uploadFiles({
          websiteId: website?.id,
          files: [...e.target.files],
          directoryId: directoryId,
          onUploadProgress: handleProgress,
        }).unwrap();

        setProgressVariant("success");
        setProgressLabel("Done!");
        setTimeout(() => {
          setShowProgress(false);
        }, 3000);
      } catch (exception: any) {
        console.log(exception);
        setProgressVariant("danger");
        setProgressLabel(exception.message);
        setProgress(100);
      }
    }
  };

  const handleOnDoubleClick = (file: FileType | Directory) => {
    onSubmit([file]);
  };

  return (
    <div className="filemanager">
      <FileManagerContext.Provider
        value={{
          onChangeDirectory: handleOnChangeDirectory,
          selected: selected,
          setSelected: setSelected,
          onDoubleClick: handleOnDoubleClick,
          multiple: false,
          directory: directory,
        }}
      >
        <input
          type="file"
          className="filemanager-files"
          multiple
          ref={ref}
          onChange={handleFilesChange}
        />

        <Breadcrumb>
          {parents.map((parent: Directory, index: number) => {
            return (
              <Breadcrumb.Item
                onClick={() => {
                  if (parent.id) {
                    setDirectoryId(parent.id);
                  }
                }}
                key={index}
                active={index === parents.length - 1}
              >
                {parent.name}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        <div className="filemanager-list">
          {items.map((item, index) => {
            return item.type === "file" ? (
              <FileComponent file={item} key={`${item.type}:${item.id}`} />
            ) : (
              <DirectoryComponent
                directory={item}
                key={`${item.type}:${item.id}`}
              />
            );
          })}
          {newDirectory && website && (
            <DirectoryComponent
              editmode={true}
              onSubmit={() => setNewDirectory(false)}
            />
          )}
        </div>
        <div className="floating-wrapper">
          {showMenu && (
            <>
              <div className="filemanager-menu">
                <Button variant="light" onClick={handleUploadFiles}>
                  Wgraj pliki
                </Button>
                <Button variant="light" onClick={handleCreateDirectory}>
                  Utwórz folder
                </Button>
              </div>
            </>
          )}

          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              setShowMenu(!showMenu);
            }}
            className="filemanager-button"
          >
            <FontAwesomeIcon icon={showMenu ? faXmark : faPlus} />
          </Fab>
        </div>

        {showProgress && (
          <Container className="progress-wrapper" fluid>
            <ProgressBar
              now={progress}
              label={progressLabel}
              variant={progressVariant}
            />
          </Container>
        )}
      </FileManagerContext.Provider>
    </div>
  );
};

export default FileManager;
