import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FileManager from "../../pages/FileManager/FileManager";
import { FileType } from "../../types/File";

interface FileManagerModalProps {
  onClose: any;
  onSelect: any;
  multiple: boolean;
}
const FileManagerModal = ({
  onClose,
  onSelect,
  multiple,
}: FileManagerModalProps): JSX.Element => {
  const [files, setFiles] = useState<FileType[]>([]);

  const handleOnSelect = (files: FileType[]) => {
    setFiles(files);
  };

  const handleOnSubmit = (files: FileType[]) => {
    onSelect(files);
    onClose();
  };

  return (
    <Modal show={true} fullscreen={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Manager plików</Modal.Title>
      </Modal.Header>

      <Modal.Body className="filemanager">
        <FileManager
          onClose={onClose}
          onSelect={handleOnSelect}
          onSubmit={handleOnSubmit}
          multiple={multiple}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Zamknij
        </Button>
        {onSelect && (
          <Button variant="primary" onClick={() => handleOnSubmit(files)}>
            Wybierz
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default FileManagerModal;
