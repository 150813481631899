import { Col, Row } from "react-bootstrap";
import useOnChange from "../../../../hooks/useOnChange";
import { Sortable } from "@progress/kendo-react-sortable";
import { MenuProps } from "../../../Blocks/Menu/Menu";
import { useEffect, useState } from "react";

const Item = (props: any): JSX.Element => {
  const { style, attributes, dataItem, forwardRef, isActive } = props;

  const handleOnNavigate = (e: any) => {
    console.log(e);
  };

  const handleOnDragOver = (e: any) => {
    console.log(e);
  };

  return (
    <div ref={forwardRef} {...attributes} style={{ ...style }}>
      <div>{dataItem.label}</div>
      <div style={{ marginLeft: 30 }}>
        <Sortable
          idField="id"
          itemUI={Item}
          data={dataItem.items ?? []}
          onDragOver={handleOnDragOver}
          onNavigate={handleOnNavigate}
        />
      </div>
    </div>
  );
};

const mapIds = (item: any) => {
  return {
    ...item,
    id: Math.random(),
    items: item.items?.map(mapIds),
  };
};
const Menu = (): JSX.Element => {
  const { onChange, component, style } = useOnChange();

  const [items, setItems] = useState<any>(
    (component as MenuProps).items.map(mapIds)
  );
  const handleOnNavigate = (e: any) => {
    setItems(e.newState);
  };

  const handleOnDragOver = (e: any) => {
    setItems(e.newState);
  };

  return (
    <Row>
      <Col>
        {component && (
          <Sortable
            idField="id"
            itemUI={Item}
            data={items}
            onDragOver={handleOnDragOver}
            onNavigate={handleOnNavigate}
          />
        )}
      </Col>
    </Row>
  );
};

export default Menu;
