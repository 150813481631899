import { Row } from "react-bootstrap";
import Block from "../Block/Block";
import blocks from "../../../constants/Blocks";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";

const Blocks = () => {
  const { editortype } = useSelector((state: RootState) => state.editor);
  return (
    <Row>
      {blocks
        .filter(
          (block) => block.mode.findIndex((mode) => mode === editortype) >= 0
        )
        .map((block, index) => {
          //   console.log(block);

          return (
            <Block
              key={index}
              content={block.content}
              name={block.name}
              icon={block.icon}
            />
          );
        })}
    </Row>
  );
};

export default Blocks;
