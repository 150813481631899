import { useDynamicValue } from "../../../hooks/useDynamicValue";
import useEditor from "../../../hooks/useEditor";
import { BaseBlock } from "../../../types/Editor/Block";
import InlineEditor from "../../Editor/Toolbar/Toolbar";
import useStyles from "../../../hooks/useStyles";
import "./Image.scss";

export interface ImageProps extends BaseBlock {
  component: "image";
  src: string;
}

const Image = (props: ImageProps) => {
  const newProps = useEditor(props);

  const [src] = useDynamicValue(props.src);
  const style = useStyles(props);

  return (
    <div className="image-wrapper" {...newProps} style={style}>
      <InlineEditor {...newProps} />
      <img src={src} className="image" />
    </div>
  );
};

export default Image;
