import { createSlice } from "@reduxjs/toolkit";

export const TokenReducer = createSlice({
  name: "token",
  initialState: null,
  reducers: {
    set: (state, action) => action.payload,
    logout: () => null,
  },
});

export const { set, logout } = TokenReducer.actions;
