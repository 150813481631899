import { BaseBlock } from "../../../types/Editor/Block";
import "./Marker.scss";

export interface MarkerProps extends BaseBlock {
  component: "marker";
}

const Marker = (props: MarkerProps) => {
  return <div className="marker"></div>;
};

export default Marker;
