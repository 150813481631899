import { useLocation } from "react-router-dom";
import { useGetByPathQuery } from "../../services/ContentService";
import Layout from "../Blocks/Layout/Layout";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { setRef } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

const LinkResolver = (): JSX.Element => {
  const location = useLocation();

  const { data: dataResponse } = useGetByPathQuery({
    path: location.pathname,
  });

  const { data, layout, data_type: datatype } = dataResponse ?? {};

  const { refresh, setRefresh } = useContext(AppContext);
  useEffect(() => {
    setRefresh(!refresh);
  }, [data, layout]);

  return layout && data ? (
    <Layout
      uuid=""
      component="layout"
      data={data}
      layout={layout}
      datatype={datatype}
    />
  ) : (
    <></>
  );
};

export default LinkResolver;
