import { Col, Form, InputGroup, Row } from "react-bootstrap";
import useOnChange from "../../../../hooks/useOnChange";
import useStyles from "../../../../hooks/useStyles";
import { useEffect } from "react";
import { LinkProps } from "@mui/material";

const Href = (): JSX.Element => {
  const { onChange, component, style } = useOnChange();

  const handleOnChange = (e: any) => {
    onChange({ href: e.target.value });
  };

  return (
    <>
      <Row>
        <Col>
          <InputGroup>
            <Form.Control
              value={(component as LinkProps)?.href ?? ""}
              onChange={handleOnChange}
              type="number"
              name="text"
              className="text-center"
            />

            <InputGroup.Text>px</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
};

export default Href;
