import { Form as BootstrapForm, Button } from "react-bootstrap";
import Input from "../../components/Form/Input/Input";
import Checkbox from "../../components/Form/Checkbox/Checkbox";
import { ContentType } from "../../types/ContentType";
import { useFormContext } from "react-hook-form";
import { Select } from "../../components/Form/Select/Select";
import {
  useGetLayoutQuery,
  useListLayoutsQuery,
} from "../../services/LayoutService";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { skipToken } from "@reduxjs/toolkit/query";
import constants from "../../Constants";
import { useTaxonomyTypeListQuery } from "../../services/TaxonomyTypeService";
import { TaxonomyType } from "../../types/TaxonomyType";

interface FormProps {
  onSubmit: any;
}
const Form = ({ onSubmit }: FormProps): JSX.Element => {
  const { watch } = useFormContext();
  const { website } = useContext(AppContext);
  const archivable = watch("archivable");
  const isPublic = watch("public");
  const { data: archiveLayoutsResponse } = useListLayoutsQuery(
    website
      ? {
          websiteId: website.id,
          params: { type: constants.LAYOUT_TYPE_ARCHIVE },
        }
      : skipToken
  );

  const archiveLayouts = archiveLayoutsResponse?.data;

  const { data: singleLayoutsResponse } = useListLayoutsQuery(
    website
      ? {
          websiteId: website.id,
          params: { type: constants.LAYOUT_TYPE_SINGLE },
        }
      : skipToken
  );

  const singleLayouts = singleLayoutsResponse?.data;

  const { data: taxonomyTypesResponse } = useTaxonomyTypeListQuery(
    website
      ? {
          websiteId: website.id,
        }
      : skipToken
  );

  const taxonomyTypes = taxonomyTypesResponse?.data;

  return (
    <BootstrapForm onSubmit={onSubmit}>
      <Input<ContentType> type="text" name="name" label="Nazwa" />
      <Checkbox<ContentType> name="hierarchical" label="Hierarchiczne" />
      <Checkbox<ContentType> name="public" label="Publiczne" />

      <Checkbox<ContentType> name="archivable" label="Posiadają listę" />
      <Checkbox<ContentType>
        name="commentable"
        label="Zezwalaj na komentarze"
      />

      {archivable && (
        <Select<ContentType>
          name="archive_layout"
          label="Szablon listy"
          valueKey="id"
          labelKey="title"
          options={archiveLayouts ?? []}
        />
      )}
      {isPublic && (
        <Select<ContentType>
          allowDefault={true}
          name="single_layout"
          valueKey="id"
          labelKey="title"
          label="Szablon pojedynczego wpisu"
          options={singleLayouts ?? []}
        />
      )}

      <Select<ContentType>
        name="taxonomy_types"
        multiple={true}
        valueKey="id"
        labelKey="name"
        options={taxonomyTypes ?? []}
        label="Typy taksonomii"
      />

      <Button variant="primary" type="submit">
        Zapisz
      </Button>
    </BootstrapForm>
  );
};

export default Form;
