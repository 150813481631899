import { createContext } from "react";
import { Item } from "../pages/FileManager/FileManager";
import { Directory } from "../types/Directory";

interface FileManagerContextProps {
  selected: Item[];
  setSelected: any;
  multiple: boolean;
  onChangeDirectory: any;
  onDoubleClick: any;
  directory?: Directory;
}

const FileManagerContext = createContext<FileManagerContextProps>({
  selected: [],
  setSelected: () => {},
  multiple: true,
  onDoubleClick: () => {},
  onChangeDirectory: () => {},
});

export default FileManagerContext;
