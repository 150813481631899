import { useContext, useState } from "react";
import { Button, ButtonGroup, Col, Row, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import constants from "../../Constants";

import DataTable from "../../components/Admin/DataTable/DataTable";
import { skipToken } from "@reduxjs/toolkit/query";
import usePermissions from "../../hooks/usePermissions";
import AppContext from "../../context/AppContext";
import {
  useContentTypeListQuery,
  useRemoveContentTypeMutation,
} from "../../services/ContentTypeService";
import { ContentType } from "../../types/ContentType";
import { DefaultListParams, ListParams } from "../../types/Api/ListParams";

const ContentTypes = (): JSX.Element => {
  const [removeContentType] = useRemoveContentTypeMutation();
  const { website } = useContext(AppContext);

  const [params, setParams] = useState<ListParams>(DefaultListParams);

  const { currentData: contentTypesResponse, isFetching } =
    useContentTypeListQuery(
      website
        ? {
            websiteId: website.id,
            params: params,
          }
        : skipToken
    );
  const rows = contentTypesResponse?.data ?? [];
  const meta = contentTypesResponse?.meta ?? {
    total: 0,
    per_page: 0,
  };

  const navigate = useNavigate();

  const { can } = usePermissions(website);
  const permissions = constants.permissions;

  const columns = [
    { field: "name", headerName: "Nazwa", flex: 1 },
    {
      field: "actions",
      headerName: "Akcje",
      width: 150,
      renderCell: (params: any) => {
        return (
          <ButtonGroup size="sm">
            {can(permissions.CONTENTTYPE_UPDATE) && (
              <Button
                disabled={!params.row.editable}
                variant="warning"
                onClick={() =>
                  navigate(`/admin/content-types/${params.row.id}`)
                }
              >
                Edytuj
              </Button>
            )}
            {can(permissions.CONTENTTYPE_DELETE) && (
              <Button
                disabled={!params.row.removable}
                variant="danger"
                onClick={() => handleOnDelete(params.row)}
              >
                Usuń
              </Button>
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params, loading: true });
  };

  const handleOnDelete = (contentType: ContentType) => {
    Swal.fire({
      title: "Czy jesteś pewien?",
      icon: "question",
      confirmButtonText: "Usuń",
      cancelButtonText: "Anuluj",
      showCancelButton: true,
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        removeContentType(contentType);
      }
    });
  };

  return can(permissions.CONTENTTYPE_VIEWANY) ? (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h3 className="mb-2">Typy treści</h3>
          </Col>
          <Col className="text-right">
            {can(permissions.CONTENTTYPE_CREATE) && (
              <Link to={`/admin/content-types/new`}>
                <Button variant="primary">Dodaj</Button>
              </Link>
            )}
          </Col>
        </Row>
      </Container>
      <DataTable
        onParamsChange={onParamsChange}
        params={{ ...params, loading: isFetching }}
        rows={rows}
        meta={meta}
        columns={columns}
      />
    </>
  ) : (
    <Navigate to="/admin/forbidden" />
  );
};

export default ContentTypes;
