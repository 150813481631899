import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "./Block.scss";
import { setSelectedComponent } from "../../../store/reducers/Editor";
import { generateKeys } from "../../../hooks/useEditor";

const Block = ({ icon, name, content }: any) => {
  const dispatch = useDispatch();

  const handleOnDragStart = () => {
    const block = generateKeys(content);
    dispatch(setSelectedComponent(block));
  };

  return (
    <Col md={6} className="block-wrapper">
      <div className="block" draggable={true} onDragStart={handleOnDragStart}>
        <img src={icon ?? "https://via.placeholder.com/200x150"} alt="" />
        <p>{name}</p>
      </div>
    </Col>
  );
};

export default Block;
