import { useContext } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import AdminContext from "../../../context/AdminContext";

interface TopbarProps {}

const Topbar = (props: TopbarProps): JSX.Element => {
  const { leftMenu, rightMenu } = useContext(AdminContext);

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container fluid>
          <Nav className="me-auto">
            {leftMenu ?? <Nav.Link href="/admin/dashboard">Admin</Nav.Link>}
          </Nav>
          <Nav>{rightMenu ?? <Nav.Link href="/wyloguj">Wyloguj</Nav.Link>}</Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default Topbar;
