import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useState } from "react";
import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import constants from "../../Constants";
import DataTable from "../../components/Admin/DataTable/DataTable";
import AppContext from "../../context/AppContext";
import usePermissions from "../../hooks/usePermissions";
import {
  useListContentQuery,
  useRemoveContentMutation,
} from "../../services/ContentService";
import { useGetContentTypeQuery } from "../../services/ContentTypeService";
import { DefaultMeta } from "../../types/Api/ListMeta";
import { DefaultListParams, ListParams } from "../../types/Api/ListParams";
import { Content } from "../../types/Content";

const Contents = (): JSX.Element => {
  const { website } = useContext(AppContext);
  const { contentTypeId } = useParams();
  const [remove] = useRemoveContentMutation();
  const { currentData: contentTypeResponse } = useGetContentTypeQuery(
    Number(contentTypeId)
  );
  const [params, setParams] = useState<ListParams>(DefaultListParams);

  const contentType = contentTypeResponse?.data;
  const { currentData: contentsResponse, isFetching } = useListContentQuery(
    website && contentType
      ? {
          websiteId: website.id,
          contentTypeId: contentType.id,
          params,
        }
      : skipToken
  );

  const rows = contentsResponse?.data ?? [];
  const meta = contentsResponse?.meta ?? DefaultMeta;

  const navigate = useNavigate();
  const { can } = usePermissions(website);
  const permissions = constants.permissions;

  const handleOnDelete = (content: Content) => {
    Swal.fire({
      title: "Czy jesteś pewien?",
      icon: "question",
      confirmButtonText: "Usuń",
      cancelButtonText: "Anuluj",
      showCancelButton: true,
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        remove(content);
      }
    });
  };
  const columns = [
    { field: "title", headerName: "Tytuł", flex: 1 },
    { field: "full_path", headerName: "Link", flex: 1 },
    {
      field: "actions",
      headerName: "Akcje",
      width: 200,
      renderCell: (params: any) => {
        return (
          <ButtonGroup size="sm">
            {can(permissions.CONTENT_VIEW) && (
              <Button
                variant="success"
                onClick={() => navigate(params.row.full_path)}
              >
                Zobacz
              </Button>
            )}
            {can(permissions.CONTENT_UPDATE) && (
              <Button
                variant="warning"
                onClick={() => navigate(`/editor/content/${params.row.id}`)}
              >
                Edytuj
              </Button>
            )}
            {can(permissions.CONTENT_DELETE) && (
              <Button
                variant="danger"
                onClick={() => handleOnDelete(params.row)}
              >
                Usuń
              </Button>
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params });
  };

  return can(permissions.CONTENT_VIEWANY) ? (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h3 className="mb-2">{contentType?.name}</h3>
          </Col>
          <Col className="text-right">
            {can(permissions.CONTENT_CREATE) && (
              <Link to={`/editor/content/new/content-type/${contentTypeId}`}>
                <Button variant="primary">Dodaj</Button>
              </Link>
            )}
          </Col>
        </Row>
      </Container>

      <DataTable
        onParamsChange={onParamsChange}
        params={{ ...params, loading: isFetching }}
        rows={rows}
        meta={meta}
        columns={columns}
      />
    </>
  ) : (
    <Navigate to="/admin/forbidden" />
  );
};

export default Contents;
