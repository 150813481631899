import { createContext } from "react";
import { Website } from "../types/Website";

interface AppContextType {
  website?: Website;
  refresh: boolean;
  setRefresh: any;
}

const AppContext = createContext<AppContextType>({
  refresh: true,
  setRefresh: () => {},
});

export default AppContext;
