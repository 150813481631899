import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { set } from "../../store/reducers/Token";

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = searchParams.get("access_token");
    dispatch(set(token));
    navigate("/");
  }, []);

  return null;
};

export default Login;
