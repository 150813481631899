import { Accordion, Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import "./Settings.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { map } from "../../Components";
import { map as settingsMap } from "./SettingsMap";
const Settings = (): JSX.Element => {
  const { selectedComponent } = useSelector((state: RootState) => state.editor);
  const blockParams = map.find(
    (block) => block.name === selectedComponent?.component
  );

  const settings = blockParams?.settings ?? [];

  return (
    <Accordion defaultActiveKey={settings[0]?.name} className="settings">
      {settings?.map((settingName: string, index: number) => {
        const {
          label,
          name,
          component: Setting,
        } = settingsMap.find((item) => item.name === settingName) ?? {};

        return (
          <Accordion.Item key={index} eventKey={settingName}>
            <Accordion.Header>{label}</Accordion.Header>
            <Accordion.Body>
              <Setting />
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default Settings;
