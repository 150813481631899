import { Col, Form, InputGroup, Row } from "react-bootstrap";
import useOnChange from "../../../../hooks/useOnChange";
import useStyles from "../../../../hooks/useStyles";
import { useEffect } from "react";

const Size = (): JSX.Element => {
  const { onChange, component, style } = useOnChange();

  const handleOnChange = (e: any) => {
    const style = { [e.target.name]: Number(e.target.value) };
    onChange({ style: style });
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Label>Rozmiar (wysokość/szerokość)</Form.Label>
          <InputGroup size="sm">
            <Form.Control
              value={style?.height ?? 0}
              onChange={handleOnChange}
              type="number"
              name="height"
              className="text-center"
            />
            <Form.Control
              value={style?.width ?? 0}
              onChange={handleOnChange}
              type="number"
              name="width"
              className="text-center"
            />

            <InputGroup.Text>px</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Label>Minimalny rozmiar (wysokość/szerokość)</Form.Label>
          <InputGroup size="sm">
            <Form.Control
              value={style?.minHeight ?? 0}
              onChange={handleOnChange}
              type="number"
              name="minHeight"
              className="text-center"
            />
            <Form.Control
              value={style?.minWidth ?? 0}
              onChange={handleOnChange}
              type="number"
              name="minWidth"
              className="text-center"
            />

            <InputGroup.Text>px</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Label>Maksymalny rozmiar (wysokość/szerokość)</Form.Label>
          <InputGroup size="sm">
            <Form.Control
              value={style?.maxHeight ?? 0}
              onChange={handleOnChange}
              type="number"
              name="maxHeight"
              className="text-center"
            />
            <Form.Control
              value={style?.maxWidth ?? 0}
              onChange={handleOnChange}
              type="number"
              name="maxWidth"
              className="text-center"
            />

            <InputGroup.Text>px</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
};

export default Size;
