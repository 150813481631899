import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import constants from "../../Constants";
import { ShowErrorAlert } from "../../functions/showErrorAlert";
import { FormProvider, useForm } from "react-hook-form";

import Form from "./Form";
import AppContext from "../../context/AppContext";
import { useGetTaxonomyTypeQuery } from "../../services/TaxonomyTypeService";
import usePermissions from "../../hooks/usePermissions";
import { Taxonomy } from "../../types/Taxonomy";
import { useCreateTaxonomyMutation } from "../../services/TaxonomiesService";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

interface ErrorResponse {
  message: string;
  errors: Record<string, string[]>;
}

const Create = (): JSX.Element => {
  const navigate = useNavigate();
  const { taxonomyTypeId } = useParams();
  const { website } = useContext(AppContext);
  const { currentData: taxonomyTypeRepsponse } = useGetTaxonomyTypeQuery(
    Number(taxonomyTypeId)
  );
  const taxonomyType = taxonomyTypeRepsponse?.data;

  const [createTaxonomy] = useCreateTaxonomyMutation();

  const { can } = usePermissions(website);
  const permissions = constants.permissions;

  const methods = useForm<Taxonomy>();

  const handleOnSubmit = async (taxonomy: Taxonomy) => {
    if (website && taxonomyType) {
      try {
        await createTaxonomy({
          websiteId: website.id,
          taxonomyTypeId: taxonomyType.id,
          taxonomy: taxonomy,
        }).unwrap();

        Swal.fire({ icon: "success", title: "Zapisano!" });
        navigate(`/admin/taxonomy-types/${taxonomyTypeId}/taxonomies`);
      } catch (e) {
        const error = e as FetchBaseQueryError;
        if (error.status === 422) {
          Object.entries<Record<any, any>>(
            (error.data as ErrorResponse).errors
          ).map(([item, errors]) =>
            methods.setError(item as any, {
              message: errors.join(","),
            })
          );
        } else {
          ShowErrorAlert(
            "Ups",
            "Coś poszło nie tak. Spróbuj ponownie za chwilę."
          );
        }
      }
    }
  };

  return can(permissions.TAXONOMY_CREATE) && taxonomyType ? (
    <Container fluid>
      <Row>
        <Col>
          <h3 className="mb-2">Dodaj</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormProvider {...methods}>
            <Form
              onSubmit={methods.handleSubmit(handleOnSubmit)}
              taxonomyType={taxonomyType}
            />
          </FormProvider>
        </Col>
      </Row>
    </Container>
  ) : (
    <Navigate to="/admin/forbidden" />
  );
};

export default Create;
