import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Content } from "../../types/Content";
import Block from "../../types/Editor/Block";
import { Layout } from "../../types/Layout";

type Resolutions = "desktop" | "mobile" | "tablet";

export interface EditorStoreType {
  content?: Content;
  selectedComponent?: Block;
  editmode: boolean;
  resolution: Resolutions;
  editortype: "content" | "layout" | null;
  layout?: Layout;
}

const initialState: EditorStoreType = {
  content: undefined,
  selectedComponent: undefined,
  editmode: false,
  resolution: "desktop",
  editortype: null,
};
export const EditorReducer = createSlice({
  name: "editor",
  initialState: initialState,
  reducers: {
    setContent: (state, { payload }: PayloadAction<Content>) => {
      state.content = payload;
    },
    setSelectedComponent: (state, { payload }) => {
      state.selectedComponent = payload;
    },
    clear: (state) => {
      state.selectedComponent = undefined;
      state.content = undefined;
    },

    clearContent: (state) => {
      state.content = undefined;
    },
    clearSelectedComponent: (state) => {
      state.selectedComponent = undefined;
    },
    setResolution: (state, { payload }) => {
      state.resolution = payload;
    },
    setEditorType(state, { payload }) {
      state.editortype = payload;
    },

    setLayout(state, { payload }) {
      state.layout = payload;
    },
    setEditMode(state, { payload }) {
      state.editmode = payload;
    },
  },
});

export const {
  setContent,
  setSelectedComponent,
  clear,
  clearContent,
  clearSelectedComponent,
  setEditMode,
  setResolution,
  setEditorType,
  setLayout,
} = EditorReducer.actions;
