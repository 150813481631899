import { ListParams } from "../types/Api/ListParams";
import { PublicItemResponse } from "../types/Api/PublicItemResponse";
import { ListResponse, SingleItemResponse } from "../types/Api/Response";
import { Content } from "../types/Content";
import { MetaGroup } from "../types/MetaGroup";
import { Taxonomy } from "../types/Taxonomy";
import Api from "./Api";

interface GetByPathParams {
  path: string;
}

export const ContentService = Api.injectEndpoints({
  endpoints: (build) => ({
    getByPath: build.query<PublicItemResponse, GetByPathParams>({
      query: (params) => ({ url: `/public`, params }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "PUBLIC_CONTENT", id: result.data.id },
        "PUBLIC_CONTENT",
      ],
    }),
    getContent: build.query<SingleItemResponse<Content>, number>({
      query: (id) => `/private/contents/${id}`,
    }),

    listContent: build.query<
      ListResponse<Content>,
      { websiteId?: number; contentTypeId?: number; params: ListParams }
    >({
      query: ({ websiteId, contentTypeId, params }) => ({
        url: `/private/websites/${websiteId}/content-types/${contentTypeId}/contents`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "CONTENT", params: arg },
        "CONTENT",
      ],
    }),

    createContent: build.mutation<
      SingleItemResponse<Content>,
      { content: Content; websiteId: number; contentTypeId: number }
    >({
      query: ({ content, contentTypeId, websiteId }) => ({
        url: `/private/websites/${websiteId}/content-types/${contentTypeId}/contents`,
        body: content,
        method: "POST",
      }),
    }),
    updateContent: build.mutation<SingleItemResponse<Content>, Content>({
      query: (content) => ({
        url: `/private/contents/${content.id}`,
        body: content,
        method: "PATCH",
      }),
      invalidatesTags: (result: any, error: any, arg: Content) => [
        { type: "CONTENT", id: arg.content_type_id },
      ],
    }),
    removeContent: build.mutation<SingleItemResponse<Content>, Content>({
      query: (content) => ({
        url: `/private/contents/${content.id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result: any, error: any, arg: Content) => [
        { type: "CONTENT", id: arg.content_type_id },
      ],
    }),
    contentTaxonomies: build.query<
      ListResponse<Taxonomy>,
      { id: number; params: any }
    >({
      query: (params) => ({ url: `/private/contents/${params.id}/taxonomies` }),
    }),

    contentMetaGroups: build.query<
      ListResponse<MetaGroup>,
      { id: number; params: any }
    >({
      query: (params) => ({
        url: `/private/contents/${params.id}/meta-groups`,
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetByPathQuery,
  useContentMetaGroupsQuery,
  useContentTaxonomiesQuery,
  useCreateContentMutation,
  useGetContentQuery,
  useLazyContentMetaGroupsQuery,
  useLazyContentTaxonomiesQuery,
  useLazyGetByPathQuery,
  useLazyGetContentQuery,
  useLazyListContentQuery,
  useListContentQuery,
  useRemoveContentMutation,
  useUpdateContentMutation,
} = ContentService;
