import { SingleItemResponse } from "../types/Api/Response";
import { Website } from "../types/Website";
import Api from "./Api";

export const WebsiteService = Api.injectEndpoints({
  endpoints: (build) => ({
    byDomain: build.query<SingleItemResponse<Website>, string>({
      query: (domain) => `private/website-by-domain/${domain}`,
    }),
  }),
  overrideExisting: false,
});

export const { useByDomainQuery, useLazyByDomainQuery } = WebsiteService;
