import Block, { Style } from "../types/Editor/Block";
import useViewport from "./useViewport";
import lodash from "lodash";

const useStyles = (block?: Block) => {
  const { width } = useViewport();
  let style = { ...block?.style?.lg };

  if (width < Number(process.env.REACT_APP_BREAKPOINT_DESKTOP)) {
    style = lodash.merge({ ...style }, { ...block?.style?.md });
  }
  if (width < Number(process.env.REACT_APP_BREAKPOINT_MOBILE)) {
    style = lodash.merge({ ...style }, { ...block?.style?.xs });
  }

  return style;
};

export default useStyles;
