import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import useOnChange from "../../../../hooks/useOnChange";
import useStyles from "../../../../hooks/useStyles";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faMinimize,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

const BorderRadius = (): JSX.Element => {
  const { onChange, component, style } = useOnChange();

  const handleOnChange = (e: any) => {
    const style = { [e.target.name]: Number(e.target.value) };
    onChange({ style: style });
  };

  return (
    <>
      <Row>
        <Col>
          <InputGroup size="sm">
            <Form.Control
              value={style?.borderTopRightRadius ?? 0}
              onChange={handleOnChange}
              type="number"
              name="borderTopRightRadius"
              className="text-center"
            />
            <Form.Control
              value={style?.borderBottomRightRadius ?? 0}
              onChange={handleOnChange}
              type="number"
              name="borderBottomRightRadius"
              className="text-center"
            />
            <Form.Control
              value={style?.borderBottomLeftRadius ?? 0}
              onChange={handleOnChange}
              type="number"
              name="borderBottomLeftRadius"
              className="text-center"
            />
            <Form.Control
              value={style?.borderTopLeftRadius ?? 0}
              onChange={handleOnChange}
              type="number"
              name="borderTopLeftRadius"
              className="text-center"
            />
            <InputGroup.Text>px</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
};

export default BorderRadius;
