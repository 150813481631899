import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import useOnChange from "../../../../hooks/useOnChange";
import useStyles from "../../../../hooks/useStyles";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faArrowsLeftRight,
  faArrowsUpDown,
  faBold,
  faItalic,
  faTextHeight,
  faUnderline,
} from "@fortawesome/free-solid-svg-icons";

const BlockSize = (): JSX.Element => {
  const { onChange, component, style } = useOnChange();

  return (
    <Row>
      <Col>
        <InputGroup size="sm">
          <InputGroup.Text>
            <FontAwesomeIcon icon={faArrowsLeftRight} />
          </InputGroup.Text>
          <Form.Control />
          <InputGroup.Text>px</InputGroup.Text>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faArrowsUpDown} />
          </InputGroup.Text>
          <Form.Control />
          <InputGroup.Text>px</InputGroup.Text>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default BlockSize;
