import { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import constants from "../../Constants";

import { skipToken } from "@reduxjs/toolkit/query";
import DataTable from "../../components/Admin/DataTable/DataTable";
import AppContext from "../../context/AppContext";
import usePermissions from "../../hooks/usePermissions";
import {
  useListLayoutsQuery,
  useRemoveLayoutMutation,
} from "../../services/LayoutService";
import { DefaultListParams, ListParams } from "../../types/Api/ListParams";
import { Layout } from "../../types/Layout";

const Layouts = (): JSX.Element => {
  const [removeLayout] = useRemoveLayoutMutation();
  const { website } = useContext(AppContext);
  const [params, setParams] = useState<ListParams>(DefaultListParams);

  const { currentData: layoutsResponse, isFetching } = useListLayoutsQuery(
    website
      ? {
          websiteId: website.id,
          params: params,
        }
      : skipToken
  );
  const rows = layoutsResponse?.data ?? [];

  const meta = layoutsResponse?.meta ?? {
    total: 0,
    per_page: 0,
  };

  const navigate = useNavigate();

  const { can } = usePermissions(website);
  const permissions = constants.permissions;

  const columns = [
    { field: "title", headerName: "Nazwa", flex: 1 },
    {
      field: "actions",
      headerName: "Akcje",
      width: 150,
      renderCell: (params: any) => {
        return (
          <ButtonGroup size="sm">
            {can(permissions.LAYOUT_UPDATE) && (
              <Button
                variant="warning"
                onClick={() => navigate(`/editor/layout/${params.row.id}`)}
              >
                Edytuj
              </Button>
            )}
            {can(permissions.LAYOUT_DELETE) && (
              <Button
                variant="danger"
                onClick={() => handleOnDelete(params.row)}
              >
                Usuń
              </Button>
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params, loading: true });
  };

  useEffect(() => {
    // dispatch(all(website.id, taxonomyTypeId, params)).then(() => {
    //   setParams({ ...params, loading: false });
    // });
  }, [params.page, params.per_page, params.sort, params.direction]);

  const handleOnDelete = (layout: Layout) => {
    Swal.fire({
      title: "Czy jesteś pewien?",
      icon: "question",
      confirmButtonText: "Usuń",
      cancelButtonText: "Anuluj",
      showCancelButton: true,
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        removeLayout(layout);
      }
    });
  };

  return can(permissions.LAYOUT_VIEWANY) ? (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h3 className="mb-2">Szablony</h3>
          </Col>
          <Col className="text-right">
            {can(permissions.LAYOUT_CREATE) && (
              <Link to={`/editor/layout/new`}>
                <Button variant="primary">Dodaj</Button>
              </Link>
            )}
          </Col>
        </Row>
      </Container>
      <DataTable
        onParamsChange={onParamsChange}
        params={{ ...params, loading: isFetching }}
        rows={rows}
        meta={meta}
        columns={columns}
      />
    </>
  ) : (
    <Navigate to="/admin/forbidden" />
  );
};

export default Layouts;
