const constants = {
  DATA_TYPE_CONTENT: 'App\\Models\\Content',
  DATA_TYPE_CONTENT_TYPE: 'App\\Models\\ContentType',
  DATA_TYPE_TAXONOMY_TYPE: 'App\\Models\\TaxonomyType',
  DATA_TYPE_TAXONOMY: 'App\\Models\\Taxonomy',

  LAYOUT_TYPE_SINGLE: 0,
  LAYOUT_TYPE_ARCHIVE: 1,
  LAYOUT_TYPE_PARTIAL: 2,
  LAYOUT_TYPE_LOOP: 3,

  META_TYPE_INTEGER: 0,
  META_TYPE_FLOAT: 1,
  META_TYPE_STRING: 2,
  META_TYPE_RADIO: 3,
  META_TYPE_CHECKBOX: 4,
  META_TYPE_SELECT: 5,
  META_TYPE_COLOR: 6,
  META_TYPE_FILE: 7,
  META_TYPE_TEXT: 8,
  META_TYPE_RELATION: 9,
  META_TYPE_REPEATABLE: 10,
  META_TYPE_ROW: 11,
  permissions:
  {
    ROLE_GRANT: "role_grant",
    ROLE_REVOKE: "role_revoke",
    PERMISSION_GRANT: "permission_grant",
    PERMISSION_REVOKE: "permission_revoke",
    DOMAIN_ATTACH: "domain_attach",
    DOMAIN_DETACH: "domain_detach",
    CONTENT_CREATE: "content_create",
    CONTENT_VIEW: "content_view",
    CONTENT_UPDATE: "content_update",
    CONTENT_DELETE: "content_delete",
    CONTENT_VIEWANY: "content_viewAny",
    CONTENTTYPE_CREATE: "contentType_create",
    CONTENTTYPE_VIEW: "contentType_view",
    CONTENTTYPE_UPDATE: "contentType_update",
    CONTENTTYPE_DELETE: "contentType_delete",
    CONTENTTYPE_VIEWANY: "contentType_viewAny",
    TAXONOMY_CREATE: "taxonomy_create",
    TAXONOMY_VIEW: "taxonomy_view",
    TAXONOMY_UPDATE: "taxonomy_update",
    TAXONOMY_DELETE: "taxonomy_delete",
    TAXONOMY_VIEWANY: "taxonomy_viewAny",
    TAXONOMYTYPE_CREATE: "taxonomyType_create",
    TAXONOMYTYPE_VIEW: "taxonomyType_view",
    TAXONOMYTYPE_UPDATE: "taxonomyType_update",
    TAXONOMYTYPE_DELETE: "taxonomyType_delete",
    TAXONOMYTYPE_VIEWANY: "taxonomyType_viewAny",
    METAGROUP_CREATE: "metaGroup_create",
    METAGROUP_VIEW: "metaGroup_view",
    METAGROUP_UPDATE: "metaGroup_update",
    METAGROUP_DELETE: "metaGroup_delete",
    METAGROUP_VIEWANY: "metaGroup_viewAny",
    METATYPE_CREATE: "metaType_create",
    METATYPE_VIEW: "metaType_view",
    METATYPE_UPDATE: "metaType_update",
    METATYPE_DELETE: "metaType_delete",
    METATYPE_VIEWANY: "metaType_viewAny",
    DOMAIN_CREATE: "domain_create",
    DOMAIN_VIEW: "domain_view",
    DOMAIN_UPDATE: "domain_update",
    DOMAIN_DELETE: "domain_delete",
    DOMAIN_VIEWANY: "domain_viewAny",
    EMAIL_CREATE: "email_create",
    EMAIL_VIEW: "email_view",
    EMAIL_UPDATE: "email_update",
    EMAIL_DELETE: "email_delete",
    EMAIL_VIEWANY: "email_viewAny",
    WEBSITE_CREATE: "website_create",
    WEBSITE_VIEW: "website_view",
    WEBSITE_UPDATE: "website_update",
    WEBSITE_DELETE: "website_delete",
    WEBSITE_VIEWANY: "website_viewAny",
    USER_CREATE: "user_create",
    USER_VIEW: "user_view",
    USER_UPDATE: "user_update",
    USER_DELETE: "user_delete",
    USER_VIEWANY: "user_viewAny",
    LAYOUT_CREATE: "layout_create",
    LAYOUT_VIEW: "layout_view",
    LAYOUT_UPDATE: "layout_update",
    LAYOUT_DELETE: "layout_delete",
    LAYOUT_VIEWANY: "layout_viewAny",
    PRESET_CREATE: "preset_create",
    PRESET_VIEW: "preset_view",
    PRESET_UPDATE: "preset_update",
    PRESET_DELETE: "preset_delete",
    PRESET_VIEWANY: "preset_viewAny",
    DIRECTORY_CREATE: "directory_create",
    DIRECTORY_VIEW: "directory_view",
    DIRECTORY_UPDATE: "directory_update",
    DIRECTORY_DELETE: "directory_delete",
    DIRECTORY_VIEWANY: "directory_viewAny",
    FILE_CREATE: "file_create",
    FILE_VIEW: "file_view",
    FILE_UPDATE: "file_update",
    FILE_DELETE: "file_delete",
    FILE_VIEWANY: "file_viewAny",
    ROLE_CREATE: "role_create",
    ROLE_VIEW: "role_view",
    ROLE_UPDATE: "role_update",
    ROLE_DELETE: "role_delete",
    ROLE_VIEWANY: "role_viewAny"
  }

}

export default constants;