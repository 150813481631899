import { Col, Form, Row } from "react-bootstrap";

const SliderSettings = (): JSX.Element => {
  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Check // prettier-ignore
            type="checkbox"
            label="test"
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default SliderSettings;
