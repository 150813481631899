import { ListParams } from "../types/Api/ListParams";
import { SingleItemResponse, ListResponse } from "../types/Api/Response";
import { Content } from "../types/Content";
import { ContentType } from "../types/ContentType";
import { MetaGroup } from "../types/MetaGroup";
import { OnlyId } from "../types/OnlyId";
import { TaxonomyType } from "../types/TaxonomyType";
import Api from "./Api";

export const ContentTypeService = Api.injectEndpoints({
  endpoints: (build) => ({
    getContentType: build.query<SingleItemResponse<ContentType>, number>({
      query: (id?) => `/private/content-types/${id}`,
      providesTags: (result: any, error: any, arg: any) => [
        { type: "CONTENT_TYPE", id: arg },
        "CONTENT_TYPE",
      ],
    }),

    publicContents: build.query<ListResponse<Content>, number>({
      query: (id) => `/public/content-types/${id}/contents`,
    }),

    createContentType: build.mutation<SingleItemResponse<ContentType>, any>({
      query: ({ websiteId, content }) => ({
        url: `/private/websites/${websiteId}/content-types`,
        body: { content },
        method: "POST",
      }),
      invalidatesTags: ["CONTENT_TYPE"],
    }),
    contentTypeList: build.query<
      ListResponse<ContentType>,
      { websiteId?: number; params: ListParams }
    >({
      query: ({ websiteId, params }) => ({
        url: `/private/websites/${websiteId}/content-types`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "CONTENT_TYPE", params: arg },
        "CONTENT_TYPE",
      ],
    }),

    updateContentType: build.mutation<
      SingleItemResponse<ContentType>,
      ContentType
    >({
      query: (contentType) => ({
        url: `/private/content-types/${contentType.id}`,
        body: contentType,
        method: "PATCH",
      }),
      invalidatesTags: (result: any, error: any, arg: ContentType) => [
        { type: "CONTENT_TYPE", id: arg.id },
        "CONTENT_TYPE",
      ],
    }),
    removeContentType: build.mutation<
      SingleItemResponse<ContentType>,
      ContentType | OnlyId
    >({
      query: ({ id }) => ({
        url: `/private/content-types/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CONTENT_TYPE"],
    }),
    contentTypeTaxonomyTypes: build.query<
      ListResponse<TaxonomyType>,
      { id: number; params: any }
    >({
      query: (params) => ({
        url: `/private/content-types/${params.id}/taxonomy-types`,
      }),
    }),

    contentTypeMetaGroups: build.query<
      ListResponse<MetaGroup>,
      { id: number; params: any }
    >({
      query: (params) => ({
        url: `/private/content-types/${params.id}/meta-groups`,
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useContentTypeListQuery,
  useContentTypeMetaGroupsQuery,
  useContentTypeTaxonomyTypesQuery,
  useCreateContentTypeMutation,
  useGetContentTypeQuery,
  useLazyContentTypeListQuery,
  useLazyContentTypeMetaGroupsQuery,
  useLazyContentTypeTaxonomyTypesQuery,
  useLazyGetContentTypeQuery,
  useLazyPublicContentsQuery,
  usePublicContentsQuery,
  useRemoveContentTypeMutation,
  useUpdateContentTypeMutation,
} = ContentTypeService;
