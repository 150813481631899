import { Carousel as BootstrapCarousel } from "react-bootstrap";
import InlineEditor from "../../Editor/Toolbar/Toolbar";
import "./Carousel.scss";
import { BaseBlock } from "../../../types/Editor/Block";
import useEditor from "../../../hooks/useEditor";
import useStyles from "../../../hooks/useStyles";

export interface SlideProps {
  image: string;
  text: string;
  title: string;
}
export interface CarouselProps extends BaseBlock {
  component: "carousel";
  slides: SlideProps[];
}

const Carousel = (props: CarouselProps) => {
  const newProps = useEditor(props);
  const { slides } = props;
  const style = useStyles(props);

  return (
    <div {...newProps} style={style}>
      <InlineEditor {...newProps} />
      <BootstrapCarousel>
        {slides?.map((slide, index) => (
          <BootstrapCarousel.Item key={index}>
            <div
              className="slide"
              style={{ backgroundImage: `url('${slide.image}')` }}
            />
            <BootstrapCarousel.Caption>
              <h3>{slide.title}</h3>
              <p>{slide.text}</p>
            </BootstrapCarousel.Caption>
          </BootstrapCarousel.Item>
        ))}
      </BootstrapCarousel>
    </div>
  );
};

export default Carousel;
