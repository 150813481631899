import BlockType, { EditorTypes } from "../types/Editor/Block";

export interface Block {
  name: string;
  mode: EditorTypes[];
  content: BlockType;
  icon: string;
}
const AvailableBlocks: Block[] = [
  {
    name: "1 kolumna",
    mode: ["content", "layout"],
    icon: "https://via.placeholder.com/200x150",
    content: {
      component: "container",
      uuid: "",
      children: [
        {
          component: "column",
          children: [],
          uuid: "",
        },
      ],
    },
  },

  {
    name: "Treść",
    mode: ["layout"],
    icon: "https://via.placeholder.com/200x150",
    content: {
      component: "content",
      uuid: "",
    },
  },
  {
    name: "2 kolumny",
    icon: "https://via.placeholder.com/200x150",
    mode: ["content", "layout"],
    content: {
      component: "container",
      uuid: "",
      children: [
        {
          component: "column",
          children: [],
          uuid: "",
        },
        {
          component: "column",
          children: [],
          uuid: "",
        },
      ],
    },
  },
  {
    name: "3 kolumny",
    icon: "https://via.placeholder.com/200x150",
    mode: ["content", "layout"],
    content: {
      uuid: "",
      component: "container",
      children: [
        {
          component: "column",
          uuid: "",
          children: [],
        },
        {
          component: "column",
          children: [],
          uuid: "",
        },
        {
          component: "column",
          children: [],
          uuid: "",
        },
      ],
    },
  },
  {
    name: "Nagłówek",
    icon: "https://via.placeholder.com/200x150",
    mode: ["content", "layout"],
    content: {
      component: "heading",
      text: "Treść",
      uuid: "",
      level: 1,
    },
  },
  {
    name: "Tekst",
    icon: "https://via.placeholder.com/200x150",
    mode: ["content", "layout"],
    content: {
      component: "paragraph",
      uuid: "",
      text: "Treść",
    },
  },
  // {
  //   name: "Zdjęcie",
  //   mode: ["content", "layout"],
  //   content: {
  //     component: "image",
  //     src: "https://via.placeholder.com/300",
  //     uuid: "",
  //   },
  // },
  // {
  //   name: "Slider",
  //   mode: ["content", "layout"],
  //   content: {
  //     component: "carousel",
  //     slides: [
  //       {
  //         image: "https://via.placeholder.com/1890x700",
  //         title: "Przykładowy tekst",
  //         text: "Przykładowy tekst",
  //       },
  //     ],
  //   },
  // },
  // {
  //   name: "Swiper",
  //   mode: ["content", "layout"],
  //   content: {
  //     component: "swiper",
  //     slides: [
  //       {
  //         image: "https://via.placeholder.com/1890x700",
  //       },
  //       {
  //         image: "https://via.placeholder.com/1890x700",
  //       },
  //       {
  //         image: "https://via.placeholder.com/1890x700",
  //       },
  //       {
  //         image: "https://via.placeholder.com/1890x700",
  //       },
  //       {
  //         image: "https://via.placeholder.com/1890x700",
  //       },
  //       {
  //         image: "https://via.placeholder.com/1890x700",
  //       },
  //     ],
  //   },
  // },
  // {
  //   name: "Slider postów",
  //   mode: ["layout"],
  //   content: {
  //     component: "swiperPosts",
  //   },
  // },
  // {
  //   name: "Lista postów",
  //   mode: ["layout"],
  //   content: {
  //     component: "posts",
  //   },
  // },
  // {
  //   name: "Lista taksonomii",
  //   mode: ["layout"],
  //   content: {
  //     component: "taxonomies",
  //   },
  // },
  // {
  //   name: "Menu",
  //   mode: ["layout"],
  //   content: {
  //     component: "menu",
  //     items: [
  //       {
  //         label: "Menu 1",
  //         href: "/",
  //       },
  //       {
  //         label: "Menu 2",
  //         href: "/",
  //       },
  //       {
  //         label: "Menu 3",
  //         href: "/",
  //       },
  //       {
  //         label: "Menu 4",
  //         href: "/",
  //       },
  //     ],
  //   },
  // },
  // {
  //   name: "Treść",
  //   mode: ["layout"],
  //   content: {
  //     component: "content",
  //   },
  // },
];

export default AvailableBlocks;
