import {
  faLaptop,
  faMobileAlt,
  faTabletAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FrameContextConsumer } from "react-frame-component";

import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import Frame from "react-frame-component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Layout from "../../components/Blocks/Layout/Layout";
import RightMenu from "../../components/Editor/RightMenu/RightMenu";
import Sidebar from "../../components/Editor/Sidebar/Sidebar";
import AdminContext from "../../context/AdminContext";

import { generateKeys } from "../../hooks/useEditor";
import { useGetContentQuery } from "../../services/ContentService";
import { useGetContentTypeQuery } from "../../services/ContentTypeService";
import { useGetLayoutQuery } from "../../services/LayoutService";
import {
  setContent,
  setEditMode,
  setEditorType,
  setLayout,
  setResolution,
} from "../../store/reducers/Editor";
import { RootState } from "../../store/store";
import { Content } from "../../types/Content";
import Block, { EditorTypes } from "../../types/Editor/Block";
import { Layout as LayoutType } from "../../types/Layout";
import "./Editor.scss";

interface EditorProps {
  editortype: EditorTypes;
}
const defaultLayout: LayoutType = {
  id: 0,
  title: "Domyślny szablon",

  body: [
    {
      component: "container",
      uuid: "",
      children: [
        {
          component: "column",
          uuid: "",
        },
      ],
    },
  ],
};
const defaultContent: Content = {
  id: 0,
  body: [
    {
      component: "container",
      uuid: "",
      children: [
        {
          component: "column",
          uuid: "",
        },
      ],
    },
  ],
  content_type_id: 0,
  layout: defaultLayout,
};

const Editor = (props: EditorProps) => {
  const [componentOnChange, setComponentOnChange] = useState(() => {});
  const { contentId, layoutId, contentTypeId } = useParams();
  const { setLeftMenu, setRightMenu } = useContext(AdminContext);
  const { currentData: contentResponse } = useGetContentQuery(
    contentId ? Number(contentId) : skipToken
  );
  const { currentData: layoutResponse } = useGetLayoutQuery(
    layoutId ? Number(layoutId) : skipToken
  );

  const { currentData: contentTypeResponse } = useGetContentTypeQuery(
    contentTypeId ? Number(contentTypeId) : skipToken
  );

  const contentTypeData = contentTypeResponse?.data;

  const { currentData: defaultLayoutResponse } = useGetLayoutQuery(
    contentTypeData ? Number(contentTypeData.single_layout?.id) : skipToken
  );

  const layoutData = layoutResponse?.data;
  const contentData = contentResponse?.data;
  const defaultSingleLayout = defaultLayoutResponse?.data;

  const dispatch = useDispatch();

  const { resolution, content, layout } = useSelector(
    (state: RootState) => state.editor
  );

  useEffect(() => {
    dispatch(setEditMode(true));
    dispatch(setEditorType(props.editortype));

    if (contentId) {
      if (contentData) {
        dispatch(setContent(contentData));
        dispatch(setLayout(contentData.layout));
      }
    }
    if (contentTypeId) {
      if (contentTypeData) {
        dispatch(setLayout(defaultSingleLayout));
        dispatch(
          setContent({
            ...defaultContent,
            body: defaultContent.body.map(generateKeys),
          })
        );
      }
    }
    if (layoutId) {
      if (layoutId === "new") {
        dispatch(
          setLayout({
            ...defaultLayout,
            body: defaultLayout.body.map(generateKeys),
          })
        );

        dispatch(
          setContent({
            ...defaultContent,
            body: defaultContent.body.map(generateKeys),
          })
        );
      } else {
        if (layoutData) {
          dispatch(setLayout(layoutData));

          dispatch(
            setContent({
              ...defaultContent,
              body: defaultContent.body.map(generateKeys),
            })
          );
        }
      }
    }
  }, [
    contentId,
    layoutId,
    contentData,
    layoutData,
    contentTypeId,
    contentTypeData,
    defaultSingleLayout,
  ]);

  useEffect(() => {
    dispatch(setEditorType(props.editortype));
    setLeftMenu(
      <>
        <Button
          variant="light"
          className="mr-2"
          onClick={() => dispatch(setResolution("desktop"))}
        >
          <FontAwesomeIcon icon={faLaptop} />
        </Button>

        <Button
          variant="light"
          className="mr-2"
          onClick={() => dispatch(setResolution("tablet"))}
        >
          <FontAwesomeIcon icon={faTabletAlt} />
        </Button>
        <Button
          variant="light"
          onClick={() => dispatch(setResolution("mobile"))}
        >
          <FontAwesomeIcon icon={faMobileAlt} />
        </Button>
      </>
    );

    setRightMenu(
      <RightMenu
        layoutId={layoutId}
        contentId={contentId}
        contentTypeId={contentTypeId}
      />
    );
  }, [dispatch, setLeftMenu, setRightMenu]);

  const handleCallback = (e: Block) => {};

  return (
    <Container className="editor" fluid>
      <Row className="editor-wrapper">
        <Col md={9} className="iframe-wrapper">
          <Frame className={["iframe", resolution].join(" ")}>
            <FrameContextConsumer>
              {({ document: innerDocument }) => {
                if (innerDocument) {
                  innerDocument.head.innerHTML = document.head.innerHTML;
                }
                return (
                  content &&
                  layout && (
                    <Layout
                      callback={handleCallback}
                      uuid="editor"
                      component="layout"
                      data={content}
                      datatype="App\Models\Content"
                      layout={layout}
                    />
                  )
                );
              }}
            </FrameContextConsumer>
          </Frame>
        </Col>
        <Col md={3} className="sidebar">
          <Sidebar />
        </Col>
      </Row>
    </Container>
  );
};

export default Editor;
