import LayoutContext from "../../../context/LayoutContext";

import WrapperContext from "../../../context/WrapperContext";
import useEditor from "../../../hooks/useEditor";
import { Content } from "../../../types/Content";
import { ContentType } from "../../../types/ContentType";
import { BaseBlock } from "../../../types/Editor/Block";
import { Layout as LayoutType } from "../../../types/Layout";
import { Taxonomy } from "../../../types/Taxonomy";
import { TaxonomyType } from "../../../types/TaxonomyType";
import Toolbar from "../../Editor/Toolbar/Toolbar";
import EditorSwitch from "../../EditorSwitch/EditorSwitch";
import "./Layout.scss";
import useStyles from "../../../hooks/useStyles";

export interface LayoutProps extends BaseBlock {
  layout: LayoutType;
  component: "layout";
  data?: Content | ContentType | Taxonomy | TaxonomyType;
  datatype?:
    | "App\\Models\\Content"
    | "App\\Models\\ContentType"
    | "App\\Models\\TaxonomyType"
    | "App\\Models\\Taxonomy";
}
const Layout = (props: LayoutProps) => {
  const newProps = useEditor({ ...props, children: props.layout.body });
  const { children } = newProps;
  const style = useStyles(props);

  return (
    <WrapperContext.Provider value={{ type: "layout" }}>
      <div className="layout-wrapper" {...newProps} style={style}>
        <Toolbar {...newProps} />
        <EditorSwitch layoutId={props.layout.id} />
        <LayoutContext.Provider
          value={{ data: props.data, datatype: props.datatype }}
        >
          <div className="layout">{children}</div>
        </LayoutContext.Provider>
      </div>
    </WrapperContext.Provider>
  );
};
export default Layout;
