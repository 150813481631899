export interface ListParams {
  page: number;
  per_page: number;
  sort: string;
  direction: "asc" | "desc";
  loading: boolean;
}

export const DefaultListParams: ListParams = {
  page: 1,
  per_page: 15,
  sort: "name",
  direction: "asc",
  loading: true,
};
