import { combineReducers } from "redux";
import { TokenReducer } from "./Token";
import Api from "../../services/Api";
import { UserReducer } from "./User";
import { EditorReducer } from "./Editor";

const AppReducer = combineReducers({
  [Api.reducerPath]: Api.reducer,
  [UserReducer.name]: UserReducer.reducer,
  [TokenReducer.name]: TokenReducer.reducer,
  [EditorReducer.name]: EditorReducer.reducer,
});

export default AppReducer;
