import { ListParams } from "../types/Api/ListParams";
import { SingleItemResponse, ListResponse } from "../types/Api/Response";
import { Layout } from "../types/Layout";
import { OnlyId } from "../types/OnlyId";
import Api from "./Api";

export const LayoutService = Api.injectEndpoints({
  endpoints: (build) => ({
    getLayout: build.query<SingleItemResponse<Layout>, number>({
      query: (id) => `/private/layouts/${id}`,
    }),

    publicGetLayout: build.query<SingleItemResponse<Layout>, number>({
      query: (id) => `/public/layouts/${id}`,
    }),

    publicGetDefaultLayout: build.query<SingleItemResponse<Layout>, number>({
      query: (type) => `/public/layouts/${type}/default`,
    }),

    listLayouts: build.query<
      ListResponse<Layout>,
      { websiteId: number; params: ListParams | { type: number } }
    >({
      query: ({ websiteId, params }) => ({
        url: `/private/websites/${websiteId}/layouts`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "LAYOUT", params: arg },
        "LAYOUT",
      ],
    }),

    createLayout: build.mutation<
      SingleItemResponse<Layout>,
      { layout: Layout; websiteId: number }
    >({
      query: ({ websiteId, layout }) => ({
        url: `/private/websites/${websiteId}/layouts`,
        body: layout,
        method: "POST",
      }),
    }),
    updateLayout: build.mutation<SingleItemResponse<Layout>, Layout>({
      query: (layout) => ({
        url: `/private/layouts/${layout.id}`,
        body: layout,
        method: "PATCH",
      }),
    }),
    removeLayout: build.mutation<SingleItemResponse<Layout>, Layout | OnlyId>({
      query: ({ id }) => ({
        url: `/private/layouts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LAYOUT"],
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreateLayoutMutation,
  useGetLayoutQuery,
  useLazyGetLayoutQuery,
  useLazyListLayoutsQuery,
  useLazyPublicGetDefaultLayoutQuery,
  useLazyPublicGetLayoutQuery,
  useListLayoutsQuery,

  usePublicGetDefaultLayoutQuery,
  usePublicGetLayoutQuery,
  useRemoveLayoutMutation,
  useUpdateLayoutMutation,
} = LayoutService;
