import { useContext } from "react";
import LayoutContext from "../context/LayoutContext";

const useDynamicValue = (value: string) => {
  const { data } = useContext(LayoutContext);
  if (value && value.charAt(0) === "_") {
    const key = value.substr(1);
    const parts = key.split(".");

    let output: any = data ?? {};
    for (let part of parts) {
      output = output[part] ?? "";
    }
    return [output, true];
  }
  return [value, false];
};

export { useDynamicValue };
