import { faEraser, faRub } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import FileManagerModal from "../../../FileManager/FileManagerModal";
import useOnChange from "../../../../hooks/useOnChange";

const Src = (): JSX.Element => {
  const [showFileManager, setShowFileManager] = useState<boolean>(false);
  const { onChange, component, style } = useOnChange();

  const handleOnClick = () => {
    setShowFileManager(true);
  };
  const handleClear = () => {
    onChange({ src: "https://via.placeholder.com/300x300.png" });
  };

  const handleOnCloseFileManager = () => {
    setShowFileManager(false);
  };

  const handleOnSelectFile = (e: any) => {
    onChange({ src: e[0].url });
  };

  return (
    <>
      <Row>
        <Col>
          <ButtonGroup>
            <Button variant="light" onClick={handleOnClick}>
              Wybierz
            </Button>
            <Button variant="light" onClick={handleClear}>
              <FontAwesomeIcon icon={faEraser} />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      {showFileManager && (
        <FileManagerModal
          onClose={handleOnCloseFileManager}
          onSelect={handleOnSelectFile}
          multiple={false}
        />
      )}
    </>
  );
};

export default Src;
