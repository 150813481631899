import Carousel from "./Blocks/Carousel/Carousel";
import Column from "./Blocks/Column/Column";
import Container from "./Blocks/Container/Container";
import Content from "./Blocks/Content/Content";
import Heading from "./Blocks/Heading/Heading";
import Image from "./Blocks/Image/Image";
import Marker from "./Blocks/Marker/Marker";
import Menu from "./Blocks/Menu/Menu";
import Paragraph from "./Blocks/Paragraph/Pragraph";

interface Component {
  name: string;
  label: string;
  component: any;
  dropable?: string[] | boolean;
  settings?: any[];
}

const map: Component[] = [
  {
    name: "container",
    label: "Kontener",
    component: Container,
    dropable: ["column"],
  },
  {
    name: "carousel",
    label: "Slider",
    component: Carousel,
    settings: ["sliderSettings", "slides"],
  },
  {
    name: "column",
    label: "Kolumna",
    component: Column,
    dropable: true,
  },
  {
    name: "paragraph",
    label: "Paragraf",
    component: Paragraph,
    settings: [
      "margin",
      "padding",
      "font",
      "blockSize",
      "background",
      "boxShadow",
      "columnWidth",
      "border",
      "borderRadius",
    ],
  },
  {
    name: "heading",
    label: "Nagłówek",
    component: Heading,
    settings: ["margin"],
  },
  {
    name: "marker",
    label: "Marker",
    component: Marker,
  },
  {
    name: "content",
    label: "Treść",
    component: Content,
  },
  {
    name: "image",
    label: "Zdjęcie",
    component: Image,
    settings: ["src"],
  },
  {
    name: "menu",
    label: "Menu",
    component: Menu,
    settings: ["menu"],
  },
];

export { map };
