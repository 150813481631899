import Swal from "sweetalert2";

const ShowErrorAlert = (title = "", message = "") => {
  Swal.fire({
    icon: "error",
    title: title,
    text: message,
  });
};

export { ShowErrorAlert };
