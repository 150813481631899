import Input from "../../components/Form/Input/Input";
import { Row, Form as BootstrapForm, Col, Button } from "react-bootstrap";

import { Select } from "../../components/Form/Select/Select";
import { Taxonomy } from "../../types/Taxonomy";
import { TaxonomyType } from "../../types/TaxonomyType";
import { useListTaxonomiesQuery } from "../../services/TaxonomiesService";
import { useByDomainQuery } from "../../services/WebsiteService";
import { skipToken } from "@reduxjs/toolkit/query";

interface FormProps {
  onSubmit: any;
  taxonomyType: TaxonomyType;
}
const Form = ({ onSubmit, taxonomyType }: FormProps): JSX.Element => {
  const { data: websiteResponse } = useByDomainQuery(
    document.location.hostname
  );
  const website = websiteResponse?.data;

  const { data: taxonomiesResponse } = useListTaxonomiesQuery(
    website
      ? {
          websiteId: website.id,
          taxonomyTypeId: taxonomyType.id,
          params: {
            page: 1,
            per_page: 99999,
            sort: "name",
            direction: "asc",
            loading: true,
          },
        }
      : skipToken
  );
  const options = taxonomiesResponse?.data;

  return (
    <BootstrapForm onSubmit={onSubmit}>
      <Input<Taxonomy> label="Nazwa" type="text" name="title" />

      {taxonomyType.hierarchical && (
        <Select<Taxonomy>
          name="parent"
          label="Taksonomia nadrzędna"
          labelKey="title"
          valueKey="id"
          options={options ?? []}
          allowDefault={true}
        />
      )}

      <Row className="mt-3">
        <Col className="text-right">
          <Button type="submit" variant="primary">
            Zapisz
          </Button>
        </Col>
      </Row>
    </BootstrapForm>
  );
};

export default Form;
