import {
  faFolderTree,
  faGear,
  faGlobe,
  faTableCells,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Button, Row, Tab, Tabs } from "react-bootstrap";
import blocks, { Block as BlockComponenet } from "../../../constants/Blocks";

import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Block from "../Block/Block";
import { EditorTypes } from "../../../types/Editor/Block";
import AppContext from "../../../context/AppContext";
import Settings from "../Settings/Settings";
import Blocks from "../Blocks/Blocks";
import Treeview from "./Treeview";

const Sidebar = (): JSX.Element => {
  const { setRefresh, refresh } = useContext(AppContext);
  const [activeKey, setActiveKey] = useState("blocks");
  const { editortype, selectedComponent } = useSelector(
    (state: RootState) => state.editor
  );

  useEffect(() => {
    if (selectedComponent) {
      setActiveKey("settings");
    }
  }, [selectedComponent, setActiveKey]);

  const onKeyChange = (key: any) => {
    setActiveKey(key);
  };

  return (
    <Tabs activeKey={activeKey} className="mb-3" fill onSelect={onKeyChange}>
      <Tab eventKey="blocks" title={<FontAwesomeIcon icon={faTableCells} />}>
        <Blocks />
      </Tab>
      <Tab eventKey="settings" title={<FontAwesomeIcon icon={faGear} />}>
        <Settings />
      </Tab>
      <Tab eventKey="contact" title={<FontAwesomeIcon icon={faGlobe} />}>
        Tab content for Contact
      </Tab>
      <Tab eventKey="tree" title={<FontAwesomeIcon icon={faFolderTree} />}>
        <Treeview />
      </Tab>
    </Tabs>
  );
};

export default Sidebar;
