import Input from "../../components/Form/Input/Input";
import { Row, Form as BootstrapForm, Col, Button } from "react-bootstrap";

import { Select } from "../../components/Form/Select/Select";
import { useFormContext } from "react-hook-form";
import { TaxonomyType } from "../../types/TaxonomyType";
import Checkbox from "../../components/Form/Checkbox/Checkbox";
import { useListLayoutsQuery } from "../../services/LayoutService";
import AppContext from "../../context/AppContext";
import { useContext } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import constants from "../../Constants";

interface FormProps {
  onSubmit: any;
}
const Form = ({ onSubmit }: FormProps): JSX.Element => {
  const { website } = useContext(AppContext);
  const { watch } = useFormContext();
  const { data: archiveLayoutsResponse } = useListLayoutsQuery(
    website
      ? {
          websiteId: website.id,
          params: { type: constants.LAYOUT_TYPE_ARCHIVE },
        }
      : skipToken
  );

  const archiveLayouts = archiveLayoutsResponse?.data;
  const archivable = watch("archivable");

  return (
    <BootstrapForm onSubmit={onSubmit}>
      <Input<TaxonomyType> label="Nazwa" type="text" name="name" />
      <Checkbox<TaxonomyType> name="hierarchical" label="Hierarchiczne" />
      <Checkbox<TaxonomyType> name="public" label="Publiczny" />
      <Checkbox<TaxonomyType> name="archivable" label="Archivable" />

      {archivable ?? (
        <Select<TaxonomyType>
          name="archive_layout"
          options={archiveLayouts ?? []}
          valueKey="id"
          labelKey="label"
          label="Szablon archiwum"
        />
      )}

      <Row className="mt-3">
        <Col className="text-right">
          <Button type="submit" variant="primary">
            Zapisz
          </Button>
        </Col>
      </Row>
    </BootstrapForm>
  );
};

export default Form;
