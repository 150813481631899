import { Link } from "react-router-dom";
import InlineEditor from "../../Editor/Toolbar/Toolbar";
import styles from "./Menu.styles";
import { BaseBlock } from "../../../types/Editor/Block";
import useEditor from "../../../hooks/useEditor";
import useStyles from "../../../hooks/useStyles";

export interface MenuItem {
  id: string | number;
  href: string;
  label: string;
  items?: MenuItem[];
}
export interface MenuProps extends BaseBlock {
  component: "menu";
  level: number;
  items: MenuItem[];
}

const example: MenuProps = {
  component: "menu",
  uuid: "test",
  level: 0,
  items: [
    {
      id: 1,
      href: "#",
      label: "Item 1",
    },
    {
      id: 2,
      href: "#",
      label: "Item 2",
      items: [
        {
          id: 3,
          href: "#",
          label: "Item 3",
        },
        {
          id: 4,
          href: "#",
          label: "Item 4",
        },
      ],
    },
  ],
};

const Menu = (props: MenuProps) => {
  const { level = 0, items } = props;

  const newProps = useEditor(props);
  const style = useStyles(props);

  return (
    <div {...(level === 0 && newProps)} style={style}>
      {level === 0 ?? <InlineEditor {...props} />}
      <ul
        {...props}
        style={
          level === 0
            ? { ...styles.menu.default, ...styles.menu.horizontal.default }
            : { ...styles.menu.default }
        }
      >
        {items.map((item, index) => {
          return (
            <li key={index} style={styles.menu.item.default}>
              <Link to={item.href}>{item.label}</Link>
              {item.items ? (
                <Menu
                  uuid={String(index)}
                  component="menu"
                  level={level + 1}
                  items={item.items}
                ></Menu>
              ) : (
                ""
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Menu;
