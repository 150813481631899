import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import "./App.scss";

import Topbar from "./components/Admin/Topbar/Topbar";
import LinkResolver from "./components/LinkResolver/LinkResolver";
import Login from "./components/Login/Login";
import Logout from "./components/Logout/Logout";
import AppContext from "./context/AppContext";
import Admin from "./layouts/Admin";
import ContentTypes from "./pages/ContentTypes/ContentTypes";
import CreateContentType from "./pages/ContentTypes/Create";
import UpdateContentType from "./pages/ContentTypes/Update";
import Contents from "./pages/Contents/Contents";
import Dashboard from "./pages/Dashboard/Dashboard";
import Layouts from "./pages/Layouts/Layouts";
import MetaGroups from "./pages/MetaGroups/MetaGroups";
import Taxonomies from "./pages/Taxonomies/Taxonomies";
import TaxonomyTypes from "./pages/TaxonomyTypes/TaxonomyTypes";
import { useMeQuery } from "./services/AuthService";
import { useByDomainQuery } from "./services/WebsiteService";

import { useState } from "react";
import AdminContext from "./context/AdminContext";
import Editor from "./pages/Editor/Editor";
import FileManager from "./pages/FileManager/FileManager";
import CreateTaxonomy from "./pages/Taxonomies/Create";
import UpdateTaxonomy from "./pages/Taxonomies/Update";
import CreateTaxonomyType from "./pages/TaxonomyTypes/Create";
import UpdateTaxonomyType from "./pages/TaxonomyTypes/Update";

interface AppProps {}
const App = (props: AppProps): JSX.Element => {
  const { currentData: user } = useMeQuery(null);
  const { data: websiteResponse } = useByDomainQuery(
    document.location.hostname
  );
  const website = websiteResponse?.data;

  const [leftMenu, setLeftMenu] = useState<JSX.Element>();
  const [rightMenu, setRightMenu] = useState();

  const reset = () => {
    setLeftMenu(undefined);
    setRightMenu(undefined);
  };

  const [refresh, setRefresh] = useState<boolean>(true);

  return (
    <AppContext.Provider
      value={{
        website: website,
        refresh: refresh,
        setRefresh: setRefresh,
      }}
    >
      <AdminContext.Provider
        value={{
          leftMenu: leftMenu,
          setLeftMenu: setLeftMenu,
          rightMenu: rightMenu,
          setRightMenu: setRightMenu,
          reset: reset,
        }}
      >
        {user && <Topbar />}
        <BrowserRouter>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<LinkResolver />} />
            <>
              <Route path="wyloguj" element={<Logout />} />

              <Route
                path="editor/content/:contentId"
                element={<Editor editortype="content" />}
              />
              <Route
                path="editor/content/:contentId/content-type/:contentTypeId"
                element={<Editor editortype="content" />}
              />
              <Route
                path="editor/layout/:layoutId"
                element={<Editor editortype="layout" />}
              />

              <Route path="admin" element={<Admin />}>
                <Route index element={<Navigate to="/admin/dashboard" />} />
                <Route path="dashboard" element={<Dashboard />} />

                <Route path="taxonomy-types" element={<Outlet />}>
                  <Route index element={<TaxonomyTypes />} />
                  <Route
                    path=":taxonomyTypeId"
                    element={<UpdateTaxonomyType />}
                  />
                  <Route path="new" element={<CreateTaxonomyType />} />

                  <Route
                    path=":taxonomyTypeId/taxonomies"
                    element={<Taxonomies />}
                  />
                  <Route
                    path=":taxonomyTypeId/taxonomies/new"
                    element={<CreateTaxonomy />}
                  />
                  <Route
                    path=":taxonomyTypeId/taxonomies/:taxonomyId"
                    element={<UpdateTaxonomy />}
                  />
                </Route>

                <Route path="content-types" element={<Outlet />}>
                  <Route index element={<ContentTypes />} />
                  <Route
                    path=":contentTypeId"
                    element={<UpdateContentType />}
                  />
                  <Route path="new" element={<CreateContentType />} />

                  <Route
                    path=":contentTypeId/contents"
                    element={<Contents />}
                  />
                </Route>

                <Route path="meta-groups" element={<Outlet />}>
                  <Route index element={<MetaGroups />} />
                </Route>
                <Route path="layouts" element={<Outlet />}>
                  <Route index element={<Layouts />} />
                </Route>
                <Route
                  path="file-manager"
                  element={<FileManager multiple={false} />}
                />
              </Route>
            </>

            <Route path="*" element={<LinkResolver />} />
          </Routes>
        </BrowserRouter>
      </AdminContext.Provider>
    </AppContext.Provider>
  );
};

export default App;
