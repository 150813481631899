import { useContext } from "react";
import { useSelector } from "react-redux";
import LayoutContext from "../../../context/LayoutContext";
import WrapperContext from "../../../context/WrapperContext";
import useEditor from "../../../hooks/useEditor";
import { RootState } from "../../../store/store";
import { Content as ContentType } from "../../../types/Content";
import { BaseBlock } from "../../../types/Editor/Block";
import "./Content.scss";
import Toolbar from "../../Editor/Toolbar/Toolbar";
import useStyles from "../../../hooks/useStyles";

export interface ContentProps extends BaseBlock {
  component: "content";
}

const Content = (props: ContentProps) => {
  const { data } = useContext(LayoutContext);
  const body = (data as ContentType).body;
  const { editortype, editmode } = useSelector(
    (state: RootState) => state.editor
  );

  const newProps = useEditor({
    ...props,
    children: editmode ? (editortype === "content" ? body : []) : body,
  });

  const { children } = newProps;
  const style = useStyles(props);

  return (
    <div {...newProps} className="content" style={style}>
      <Toolbar {...newProps} />
      <WrapperContext.Provider value={{ type: "content" }}>
        {children}
      </WrapperContext.Provider>
    </div>
  );
};

export default Content;
