import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import FileManagerContext from "../../../context/FileManagerContext";
import { Item } from "../../../pages/FileManager/FileManager";

import {
  useCreateDirectoryMutation,
  useDeleteDirectoryMutation,
  useDeleteFileMutation,
  useUpdateDirectoryMutation,
  useUpdateFileMutation,
} from "../../../services/FileManagerService";
import { dir } from "console";
import { useByDomainQuery } from "../../../services/WebsiteService";
import { FileType } from "../../../types/File";
import { Directory } from "../../../types/Directory";

export interface FileProps {
  file?: FileType;
  editmode?: boolean;
  onSubmit?: any;
}

const File = (props: FileProps) => {
  const {
    selected,
    setSelected,
    multiple,
    onChangeDirectory,
    onDoubleClick,
    directory: parentDirectory,
  } = useContext(FileManagerContext);
  const [editMode, setEditMode] = useState<boolean>(props.editmode ?? false);
  const { data: websiteResponse } = useByDomainQuery(
    document.location.hostname
  );
  const website = websiteResponse?.data;

  const [updateFile] = useUpdateFileMutation();
  const [deleteFile] = useDeleteFileMutation();

  const isSelected = selected.find(
    (s) => s.id === props.file?.id && s.type === "file"
  );

  const handleOnClick = (e: any) => {
    if (props.file) {
      const withCtrl = e.metaKey;
      e.preventDefault();
      e.stopPropagation();
      let newSelected: Item[] = [];

      if (e.detail == 1) {
        if (withCtrl) {
          newSelected = multiple
            ? selected.filter(
                (s) => s.id !== props.file?.id && s.type === props.file?.type
              )
            : [];

          if (!isSelected) {
            newSelected.push(props.file);
          }
        } else {
          newSelected.push(props.file);
          if (isSelected) {
            setEditMode(true);
          }
        }

        setSelected(newSelected);
      }
    }
  };

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      setEditMode(false);
      setSelected([]);
      if (props.file) {
        updateFile({
          ...props.file,
          name: e.target.value,
        });
      }
      if (props.onSubmit) {
        props.onSubmit({ ...props.file, name: e.target.value });
      }
    }
  };

  const handleOnDelete = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    Swal.fire({
      icon: "question",
      title: "Czy jesteś pewien?",
      showCancelButton: true,
      cancelButtonText: "Nie",
      confirmButtonText: "Tak",
    }).then((result) => {
      if (result.isConfirmed) {
        if (props.file) {
          deleteFile(props.file);
        }
      }
    });
  };

  const cancelEditMode = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setEditMode(false);
  };
  const handleOnDoubleClick = (e: any) => {
    e.preventDefault();
    onDoubleClick(props.file);
  };

  const style = props.file?.is_image
    ? {
        backgroundImage: `url('${props.file.thumbnail_url}')`,
      }
    : {};

  return (
    <>
      <div
        className={"filemanager-item " + (isSelected ? "selected" : "")}
        onClick={handleOnClick}
        onDoubleClick={handleOnDoubleClick}
      >
        <button className="filemanager-remove" onClick={handleOnDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </button>

        <div className="filemanager-icon file" style={style} />
        {editMode ? (
          <>
            <div className="overlay" onClick={cancelEditMode}></div>

            <input
              type="text"
              autoFocus
              onKeyDown={handleOnKeyDown}
              defaultValue={props.file?.name}
              className="filemanager-label filemanager-label-input"
            />
          </>
        ) : (
          <div className="filemanager-label">{props.file?.name}</div>
        )}
      </div>
    </>
  );
};

export default File;
