import { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import constants from "../../Constants";
import { ShowErrorAlert } from "../../functions/showErrorAlert";

import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import AppContext from "../../context/AppContext";
import usePermissions from "../../hooks/usePermissions";
import {
  useGetTaxonomyQuery,
  useUpdateTaxonomyMutation,
} from "../../services/TaxonomiesService";
import { useGetTaxonomyTypeQuery } from "../../services/TaxonomyTypeService";
import { Taxonomy } from "../../types/Taxonomy";
import Form from "./Form";

interface ErrorResponse {
  message: string;
  errors: Record<string, string[]>;
}

const Update = (): JSX.Element => {
  const navigate = useNavigate();
  const { taxonomyTypeId, taxonomyId } = useParams();
  const { website } = useContext(AppContext);
  const { currentData: taxonomyTypeRepsponse } = useGetTaxonomyTypeQuery(
    Number(taxonomyTypeId)
  );
  const taxonomyType = taxonomyTypeRepsponse?.data;

  const { currentData: taxonomyRepsponse } = useGetTaxonomyQuery(
    Number(taxonomyId)
  );
  const taxonomy = taxonomyRepsponse?.data;

  const methods = useForm<Taxonomy>();

  useEffect(() => {
    methods.reset(taxonomy);
  }, [taxonomy, methods]);

  const [updateTaxonomy] = useUpdateTaxonomyMutation();

  const { can } = usePermissions(website);
  const permissions = constants.permissions;

  const handleOnSubmit = async (taxonomy: Taxonomy) => {
    if (website && taxonomyType) {
      try {
        await updateTaxonomy(taxonomy).unwrap();

        Swal.fire({ icon: "success", title: "Zapisano!" });
        navigate(`/admin/taxonomy-types/${taxonomyTypeId}/taxonomies`);
      } catch (e) {
        const error = e as FetchBaseQueryError;
        if (error.status === 422) {
          Object.entries<Record<any, any>>(
            (error.data as ErrorResponse).errors
          ).map(([item, errors]) =>
            methods.setError(item as any, {
              message: errors.join(","),
            })
          );
        } else {
          ShowErrorAlert(
            "Ups",
            "Coś poszło nie tak. Spróbuj ponownie za chwilę."
          );
        }
      }
    }
  };

  return can(permissions.TAXONOMY_UPDATE) && taxonomyType ? (
    <Container fluid>
      <Row>
        <Col>
          <h3 className="mb-2">Dodaj</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormProvider {...methods}>
            <Form onSubmit={handleOnSubmit} taxonomyType={taxonomyType} />
          </FormProvider>
        </Col>
      </Row>
    </Container>
  ) : (
    <Navigate to="/admin/forbidden" />
  );
};

export default Update;
