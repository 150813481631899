import { Col, Form, InputGroup, Row } from "react-bootstrap";
import useOnChange from "../../../../hooks/useOnChange";
import useStyles from "../../../../hooks/useStyles";
import { useEffect } from "react";

const BoxShadow = (): JSX.Element => {
  const { onChange, component, style } = useOnChange();

  const handleOnChange = (e: any) => {
    const style = { [e.target.name]: Number(e.target.value) };
    onChange({ style: style });
  };

  return (
    <>
      <Row>
        <Col>
          <InputGroup size="sm">
            <Form.Control type="number" className="text-center" />
            <Form.Control type="number" className="text-center" />
            <Form.Control type="number" className="text-center" />
            <Form.Control type="number" className="text-center" />
            <InputGroup.Text>px</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>Kolor</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check type="switch" id="custom-switch" label="Wewnątrz" />
        </Col>
      </Row>
    </>
  );
};

export default BoxShadow;
