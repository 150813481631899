import { createContext } from "react";

interface AdminContextProps {
  leftMenu?: JSX.Element;
  rightMenu?: JSX.Element;
  setLeftMenu: (leftMenu: JSX.Element) => void;
  setRightMenu: (leftMenu: any) => void;
  reset: () => void;
}

const AdminContext = createContext<AdminContextProps>({
  leftMenu: undefined,
  rightMenu: undefined,
  setLeftMenu: (leftMenu: JSX.Element) => {},
  setRightMenu: (leftMenu: JSX.Element) => {},
  reset: () => {},
});

export default AdminContext;
