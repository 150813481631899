import { SingleItemResponse } from "../types/Api/Response";
import { User } from "../types/User";
import Api from "./Api";

export const AuthService = Api.injectEndpoints({
  endpoints: (build) => ({
    me: build.query<SingleItemResponse<User>, null>({
      query: () => `/auth/me`,
      providesTags: ["ME"],
    }),
  }),
  overrideExisting: false,
});

export const { useMeQuery, useLazyMeQuery } = AuthService;
