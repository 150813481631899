import { Col, Form, InputGroup, Row } from "react-bootstrap";
import useOnChange from "../../../../hooks/useOnChange";
import useStyles from "../../../../hooks/useStyles";
import { useEffect } from "react";

const Margin = (): JSX.Element => {
  const { onChange, component, style } = useOnChange();

  const handleOnChange = (e: any) => {
    const style = { [e.target.name]: Number(e.target.value) };
    onChange({ style: style });
  };

  return (
    <Row>
      <Col>
        <InputGroup size="sm">
          <Form.Control
            value={style?.marginTop ?? 0}
            onChange={handleOnChange}
            type="number"
            name="marginTop"
            className="text-center"
          />
          <Form.Control
            value={style?.marginRight ?? 0}
            onChange={handleOnChange}
            type="number"
            name="marginRight"
            className="text-center"
          />
          <Form.Control
            value={style?.marginBottom ?? 0}
            onChange={handleOnChange}
            type="number"
            name="marginBottom"
            className="text-center"
          />
          <Form.Control
            value={style?.marginLeft ?? 0}
            onChange={handleOnChange}
            type="number"
            name="marginLeft"
            className="text-center"
          />
          <InputGroup.Text>px</InputGroup.Text>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default Margin;
