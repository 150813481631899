import useEditor from "../../../hooks/useEditor";
import { BaseBlock } from "../../../types/Editor/Block";
import Toolbar from "../../Editor/Toolbar/Toolbar";
import useStyles from "../../../hooks/useStyles";

export interface HeadingProps extends BaseBlock {
  component: "heading";
  text?: string;
  level: 1 | 2 | 3 | 4 | 5 | 6;
}

const Heading = (props: HeadingProps) => {
  const newProps = useEditor(props);
  const { children } = newProps;
  const Tag = `h${props.level}` as any;
  const style = useStyles(props);

  return (
    <div>
      <Tag {...newProps} style={style}>
        <Toolbar {...newProps} />
        {children}
      </Tag>
    </div>
  );
};

export default Heading;
