import { ListParams } from "../types/Api/ListParams";
import { SingleItemResponse, ListResponse } from "../types/Api/Response";
import { MetaGroup } from "../types/MetaGroup";
import { OnlyId } from "../types/OnlyId";
import Api from "./Api";

export const MetaGroupService = Api.injectEndpoints({
  endpoints: (build) => ({
    getMetaGroup: build.query<SingleItemResponse<MetaGroup>, number>({
      query: (id) => `/private/meta-groups/${id}`,
    }),

    listMetaGroups: build.query<
      ListResponse<MetaGroup>,
      { websiteId: number; params: ListParams }
    >({
      query: ({ websiteId, params }) => ({
        url: `/private/websites/${websiteId}/meta-groups`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "META_GROUP", params: arg },
        "META_GROUP",
      ],
    }),

    createMetaGroup: build.mutation<SingleItemResponse<MetaGroup>, any>({
      query: ({ websiteId, content }) => ({
        url: `/private/websites/${websiteId}/meta-groups`,
        body: { content },
        method: "POST",
      }),
    }),
    updateMetaGroup: build.mutation<SingleItemResponse<MetaGroup>, any>({
      query: ({ id, content }) => ({
        url: `/private/meta-groups/${id}`,
        body: { content },
        method: "PATCH",
      }),
    }),
    removeMetaGroup: build.mutation<
      SingleItemResponse<MetaGroup>,
      MetaGroup | OnlyId
    >({
      query: ({ id }) => ({
        url: `/private/meta-groups/${id}`,

        method: "DELETE",
      }),
      invalidatesTags: ["META_GROUP"],
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreateMetaGroupMutation,
  useGetMetaGroupQuery,
  useLazyGetMetaGroupQuery,
  useLazyListMetaGroupsQuery,
  useListMetaGroupsQuery,
  usePrefetch,
  useRemoveMetaGroupMutation,
  useUpdateMetaGroupMutation,
} = MetaGroupService;
