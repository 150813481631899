import { BaseSyntheticEvent } from "react";
import { FormGroup, Form } from "react-bootstrap";
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";

interface SelectProps<
  TFieldValues extends FieldValues,
  TFieldPath extends FieldPath<TFieldValues>
> {
  name: TFieldPath;
  label: string;
  multiple?: boolean;
  allowDefault?: boolean;
  options: any[];
  labelKey: string | "label";
  valueKey: string | "value";
  defaultLabel?: string;
}

const Select = <
  TFieldValues extends FieldValues,
  TFieldPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  options,
  multiple = false,
  allowDefault = true,
  labelKey = "label",
  valueKey = "value",
  defaultLabel = "Wybierz jedną z opcji",
}: SelectProps<TFieldValues, TFieldPath>) => {
  const {
    formState: { errors },
  } = useFormContext<TFieldValues>();

  return (
    <Controller<TFieldValues, TFieldPath>
      name={name}
      render={({ field }) => {
        const handleOnChange = (e: BaseSyntheticEvent) => {
          const values = Array.from(e.target.selectedOptions).map(
            (item) => (item as HTMLOptionElement).value
          );
          const selectedObjects = options.filter((option) => {
            return values.indexOf(String(option[valueKey])) !== -1;
          });

          if (multiple) {
            field.onChange(selectedObjects);
          } else {
            field.onChange(selectedObjects[0]);
          }
        };
        const value = multiple
          ? field.value?.map((v: any) => v[valueKey])
          : field.value?.[valueKey];
        return (
          <FormGroup>
            <Form.Label>{label}</Form.Label>
            <Form.Select
              {...field}
              value={value}
              onChange={handleOnChange}
              isInvalid={!!errors[name]?.message}
              multiple={multiple}
            >
              {allowDefault && (
                <option value={undefined}>{defaultLabel}</option>
              )}
              {options.map((option, index) => (
                <option key={index} value={option[valueKey]}>
                  {option[labelKey]}
                </option>
              ))}
            </Form.Select>
            {errors[name] && (
              <Form.Control.Feedback type="invalid">
                <>{errors[name]?.message}</>
              </Form.Control.Feedback>
            )}
          </FormGroup>
        );
      }}
    />
  );
};

export { Select };
