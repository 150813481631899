import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { logout as logoutAction } from "../../store/reducers/Token";

const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logoutAction());
  }, []);
  return <Navigate to="/" />;
};

export default Logout;
