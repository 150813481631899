import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Nav } from "react-bootstrap";
import {
  useCreateContentMutation,
  useUpdateContentMutation,
} from "../../../services/ContentService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  useCreateLayoutMutation,
  useUpdateLayoutMutation,
} from "../../../services/LayoutService";
import { useParams } from "react-router-dom";
import { useGetContentTypeQuery } from "../../../services/ContentTypeService";
import { skipToken } from "@reduxjs/toolkit/query";
import { useByDomainQuery } from "../../../services/WebsiteService";
import Swal from "sweetalert2";

interface RightMenuProps {
  contentId?: number | string;
  contentTypeId?: number | string;
  layoutId?: number | string;
}

const RightMenu = ({ contentId, contentTypeId, layoutId }: RightMenuProps) => {
  const [updateContent] = useUpdateContentMutation();
  const [updateLayout] = useUpdateLayoutMutation();

  const [createContent] = useCreateContentMutation();
  const [createLayout] = useCreateLayoutMutation();

  const { currentData: webisteResponse } = useByDomainQuery(
    document.location.hostname
  );
  const dispatch = useDispatch();

  const website = webisteResponse?.data;
  const { content, layout, editortype } = useSelector(
    (state: RootState) => state.editor
  );
  const { currentData: contentTypeResponse } = useGetContentTypeQuery(
    contentTypeId ? Number(contentTypeId) : skipToken
  );
  const contentType = contentTypeResponse?.data;

  const handleSave = async () => {
    try {
      if (website) {
        if (editortype === "content") {
          if (content) {
            if (contentId === "new") {
              if (contentType) {
                await createContent({
                  content: content,
                  websiteId: website.id,
                  contentTypeId: contentType.id,
                });
              }
            } else {
              await updateContent(content);
            }
          }
        } else if (editortype === "layout") {
          if (layout) {
            if (layoutId === "new") {
              await createLayout({ websiteId: website.id, layout: layout });
            } else {
              await updateLayout(layout);
            }
          }
        }
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Ups! coś poszło nie tak",
      });
    }

    Swal.fire({
      icon: "success",
      title: "Pomyślnie zapisano!",
    });
  };

  return (
    <>
      <Button variant="light" onClick={handleSave}>
        <FontAwesomeIcon icon={faSave} />
      </Button>
      <Nav.Link href="/">Wróć</Nav.Link>
      <Nav.Link href="/logout">Wyloguj</Nav.Link>
    </>
  );
};

export default RightMenu;
